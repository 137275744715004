import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import axios from 'axios';
import { useQuery } from 'react-query';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import CampoSenha from '../../components/Formulario/CampoSenha';
import CampoMultiSeletor from '@/components/Formulario/CampoMultiSeletor';
import HBox from '@/components/layout/HBox';

type CorProps = {
  value: string;
  selected: boolean;
  onClick: () => void;
};
const Cor = ({ value, selected, onClick }: CorProps) => {
  return (
    <div
      data-testid={`cor-${value}`}
      style={{
        width: '24px',
        height: '24px',
        backgroundColor: value,
        borderRadius: '50%',
        border: '1px solid var(--tc-color-gray-300)',
        outline: selected
          ? '4px solid var(--tc-color-primary-300)'
          : '0px solid var(--tc-color-primary-300)',
        cursor: 'pointer',
        transition: 'outline 100ms',
      }}
      onClick={onClick}
    />
  );
};

// crie cores baseadas nas seguintes: 2f8719, cc3b33, ffaf19, 207edd

const cores = [
  '#b580e3',
  '#c49c73',
  '#80e0e0',
  '#a3cfee',
  '#ffe0a3',
  '#f0a3a3',
  '#a3d4a3',
];

export const FormMarcador = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const newObj = !value?.id || value?.id < 0;

  const localOptions = [
    { id: 'qualquer', label: 'Todos os locais' },
    { id: 'chat', label: 'Somente chats' },
    { id: 'cliente', label: 'Somente clientes' },
  ];
  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <GridItem sm={12} md={6}>
        <CampoTexto
          instanceId="nome"
          name="nome"
          value={value?.nome ?? ''}
          onChange={v => onChange({ ...value, nome: v })}
          label="Nome"
          error={fieldErrors?.nome}
        />
      </GridItem>
      <GridItem sm={12} md={6}>
        <CampoTexto
          instanceId="descricao"
          name="descricao"
          value={value?.descricao ?? ''}
          onChange={v => onChange({ ...value, descricao: v })}
          label="Descrição"
          error={fieldErrors?.descricao}
        />
      </GridItem>
      <GridItem sm={12} md={6}>
        <CampoSeletor
          instanceId="local"
          name="local"
          noSearch
          value={value?.local}
          onChange={v => onChange({ ...value, local: v })}
          label="Locais"
          options={localOptions ?? []}
          error={fieldErrors?.local}
          placeholder="(Selecione)"
        />
      </GridItem>
      <GridItem sm={12} md={6}>
        <label>Cor</label>
        <HBox
          style={{
            height: '36px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {cores.map(cor => (
            <Cor
              key={cor}
              value={cor}
              selected={value?.cor === cor}
              onClick={() => onChange({ ...value, cor })}
            />
          ))}
        </HBox>
        {fieldErrors?.cor && <small>{fieldErrors.cor}</small>}
      </GridItem>
    </Grid>
  );
};
