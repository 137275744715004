import React from "react"
import Modal from "../layout/Modal"
import VBox from "../layout/VBox"
import CampoSenha from "../Formulario/CampoSenha"
import { useMutation } from "react-query"
import axios, { AxiosError, AxiosResponse } from "axios"


type Props = {
    show: boolean,
    onChanged: (user: any) => void
}

export const ModalAlterarSenha = ({ show = false, onChanged }: Props) => {
    const [senha, setSenha] = React.useState('')
    const [confirmarSenha, setConfirmarSenha] = React.useState('')
    const [fieldErrors, setFieldErrors] = React.useState<Record<string, string>>({});

    const senhaMutator = useMutation({
        mutationFn: async (data: { senha: string, confirmar_senha: string }) => {
            const response = await axios.post('/api/auth/me/senha', data)
            return response.data
        },
        onSuccess: (data: any) => {
            console.log('result', data)
            onChanged(data?.user)
        },
        onError: (err: AxiosError<any>) => {
            console.log(err)

            setFieldErrors(err.response?.data?.fieldErrors ?? {})
        }
    })

    const handleConfirm = () => {
        senhaMutator.mutate({
            senha,
            confirmar_senha: confirmarSenha
        })
    }

    return (
        <Modal
            closeable={false}
            ignoreOverlayClick={true}
            show={show}
            title="Alterar senha"
            onClose={() => console.log('fechar modal')}
            onConfirm={handleConfirm}
            confirmProps={{
                disabled: senhaMutator.isLoading || senhaMutator.isSuccess,
            }}
            cancelProps={{
                visible: false
            }}
        >
            <VBox>
                <p>Sua senha precisa ser alterada.</p>
                <CampoSenha
                    label="Nova senha"
                    showStrength
                    value={senha}
                    onChange={setSenha}
                    error={fieldErrors.senha}
                />
                <CampoSenha
                    label="Confirmar nova senha"
                    value={confirmarSenha}
                    onChange={setConfirmarSenha}
                    error={fieldErrors.confirmar_senha}
                />
            </VBox>
        </Modal>
    )
}