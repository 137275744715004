import { mdiSchool } from "@mdi/js"
import Botao from "./Botao"
import React from "react"
import { ModalTutorialYT } from "./ModalTutorialYT"

type Props = {
    ytId: string,
    ytSi: string,
}

export const Tutorial = ({ ytId, ytSi }: Props) => {
    const [showTutorial, setShowTutorial] = React.useState(false)

    return (
        <>
            <ModalTutorialYT ytId={ytId} ytSi={ytSi} show={showTutorial} onClose={() => setShowTutorial(false)} />
            <Botao
                variant='tutorial'
                onClick={() => setShowTutorial(true)}
                icon={mdiSchool}
                iconProps={{ size: 1.5, style: { backgroundColor: 'var(--tc-color-tutorial-400)', padding: '4px', borderRadius: '20px' } }}
                style={{ fontSize: '1.2rem', marginTop: '1rem', height: '52px' }}
            >
                Ver tutorial
            </Botao>
        </>
    )
}