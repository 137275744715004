import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import { useQuery } from 'react-query';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import AlertBox from '../../components/layout/AlertBox';
import CampoMemo from '@/components/Formulario/CampoMemo';

export const FormMensagemProgramada = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const newObj = !value?.id || value?.id < 0;

  const estiloFilaOptions = [
    { id: 'unica', label: 'Sequencial' },
    { id: 'paralela', label: 'Paralela' },
  ];

  const canaisQuery = useQuery(['canal'], async () => {
    const res = await fetch('/api/canal/box');
    return res.json();
  });

  const canaisOptions =
    canaisQuery.data?.result?.map((it: any) => ({
      id: it.id,
      label: it.nome,
    })) || [];

  const paramsList = [];
  const regexParams = /{{([^}]+)}}/g;
  let m;
  while ((m = regexParams.exec(value?.conteudo)) !== null) {
    if (m.index === regexParams.lastIndex) {
      regexParams.lastIndex++;
    }
    paramsList.push(m[1]);
  }

  const paramsValid =
    paramsList.length &&
    paramsList.every((param, index) => {
      return param == (index + 1).toString();
    });

  return (
    <>
      <Grid>
        <div style={{ position: 'absolute', height: 0 }}>
          <input
            type="text"
            name="username"
            style={{
              height: 0,
              width: 0,
              border: 'none',
              outline: 'none',
              margin: 0,
              padding: 0,
            }}
          />
          <input
            type="password"
            name="password"
            style={{
              height: 0,
              width: 0,
              border: 'none',
              outline: 'none',
              margin: 0,
              padding: 0,
            }}
          />
        </div>

        <GridItem sm={12} md={3}>
          <CampoSeletor
            instanceId='canal'
            disabled={value?.id > 0}
            label="Canal"
            value={value?.canal_id}
            onChange={v => onChange({ ...value, canal_id: v })}
            options={canaisOptions}
            optionLabel="nome"
            optionValue="id"
          />
        </GridItem>

        <GridItem sm={12} md={6}>
          <CampoTexto
            instanceId='nome'
            label="Nome"
            value={value?.nome}
            onChange={v => onChange({ ...value, nome: v })}
          />
        </GridItem>

        <GridItem sm={12} md={3}>
          <CampoSeletor
            instanceId='categoria'
            noSearch
            disabled={value?.id > 0}
            label="Categoria"
            value={value?.categoria}
            onChange={v => onChange({ ...value, categoria: v })}
            options={[
              { id: 'notificacao', label: 'Notificação' },
              { id: 'marketing', label: 'Marketing' },
              { id: 'autenticacao', label: 'Autenticação' },
            ]}
          />
        </GridItem>

        <GridItem sm={12} md={12}>
          <CampoMemo
            instanceId='conteudo'
            disabled={value?.id > 0}
            label="Texto"
            value={value?.conteudo}
            onChange={v => onChange({ ...value, conteudo: v })}
          />
        </GridItem>
      </Grid>

      {paramsList.length === 0 && (
        <AlertBox variant="info">
          <p>
            Dica: Você pode adicionar parâmetros para inserir dados depois, como
            nomes, telefones etc.
          </p>
          <p>
            Os parâmetros ficam entre chaves duplas ( &#123;&#123; &#125;&#125;
            ), e devem ser numerados sequencialmente.
          </p>
          <p>
            Exemplo: Olá, &#123;&#123;1&#125;&#125;! O evento
            &#123;&#123;2&#125;&#125; irá acontecer dia
            &#123;&#123;3&#125;&#125;.
          </p>
        </AlertBox>
      )}

      {!value?.id && !paramsValid && paramsList.length > 0 && (
        <p style={{ fontSize: '0.8rem', color: 'red' }}>
          <span style={{ fontWeight: 'bold' }}>
            Parâmetros inválidos. Os parâmetros devem seguir uma numeração
            crescente, iniciando de 1.
            <br />
            Exemplo: texto {`{{1}}`} texto {`{{2}}`} texto ...{' '}
          </span>
        </p>
      )}
      {!value?.id && !!paramsValid && paramsList.length > 0 && (
        <>
          <div style={{ marginTop: '0.5rem' }}>
            <b>Exemplos</b>
          </div>
          <span>
            Para submeter à avaliação, precisamos incluir também amostras de
            dados que ocuparão o lugar dos parâmetros no conteúdo.
          </span>

          {paramsList.map((param, idx) => (
            <Grid key={idx}>
              <GridItem md={2}>{param}</GridItem>
              <GridItem md={10}>
                <CampoTexto
                  value={value?.params?.[param]}
                  onChange={v =>
                    onChange({
                      ...value,
                      params: { ...value?.params, [param]: v },
                    })
                  }
                />
              </GridItem>
            </Grid>
          ))}

          <div style={{ marginTop: '0.5rem' }}>
            <b>A mensagem ficaria assim</b>
          </div>

          <pre
            style={{
              padding: '0.5rem',
              border: '1px solid #DDD',
              borderRadius: '8px',
            }}
          >
            {value?.conteudo?.replace(
              regexParams,
              (_m: any, p1: any) => value?.params?.[p1] || `{{${p1}}}`,
            )}
          </pre>
        </>
      )}
    </>
  );
};
