import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback, useState } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import Icon from '@mdi/react';
import { mdiLoading, mdiPlus, mdiSync } from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import { FiltroCanais } from '../historico_mensagens/FiltroCanais';
import Grid, { GridItem } from '../../components/layout/Grid';
import { parseISO, format } from 'date-fns';
import { FormCampanha } from './FormCampanha';
import { Tutorial } from '@/components/Tutorial';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type Props = {};

const Page = ({ }: Props) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const [importCompleted, setImportCompleted] = useState(false);
  const [showModalRetry, setShowModalRetry] = useState(false);

  const [filterCanais, setFilterCanais] = React.useState<string[]>([]);

  const headers: DataTableHeader[] = [
    { label: 'Código', width: '10%', column: 'id' },
    {
      label: 'Data criação',
      width: '15%',
      render: (row: any) => (
        <span>{format(parseISO(row.data_criacao), 'dd/MM/yyyy HH:mm')}</span>
      ),
    },
    {
      label: 'Canal associado',
      width: '50%',
      render: row => <span>{row.canal?.nome ?? row.canal_nome}</span>,
    },
    {
      label: 'Q. contatos',
      width: '10%',
      column: 'qtd_clientes',
    },
    {
      label: 'Estado atual',
      alignCenter: true,
      width: '15%',
      render: (row: any) => {
        const progresso = campanhaProgressQuery.data?.result?.find(
          (it: any) => it.id === row.id,
        );

        const totais = progresso
          ? {
            sucessos: progresso.qtd_sucessos,
            erros: progresso.qtd_erros,
            naoProcessados:
              progresso.qtd_clientes -
              progresso.qtd_sucessos -
              progresso.qtd_erros,
            total: progresso.qtd_clientes,
          }
          : {
            sucessos: row.qtd_sucessos,
            erros: row.qtd_erros,
            naoProcessados:
              row.qtd_clientes - row.qtd_sucessos - row.qtd_erros,
            total: row.qtd_clientes,
          };

        const percs = {
          sucessos:
            totais.total === 0
              ? 0
              : Math.round((totais.sucessos * 100.0) / totais.total),
          erros:
            totais.total === 0
              ? 0
              : Math.round((totais.erros * 100.0) / totais.total),
          naoProcessados:
            totais.total === 0
              ? 0
              : Math.round((totais.naoProcessados * 100.0) / totais.total),
        };

        return (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                alignItems: 'center',
                justifyContent: 'center',
                height: '20px',
                borderLeft: progresso
                  ? '1px solid var(--tc-color-success)'
                  : undefined,
              }}
            >
              <span
                style={{
                  width: '3rem',
                  color: '#28a745',
                  textAlign: 'end',
                }}
              >
                {percs.sucessos}%
              </span>
              <div
                style={{
                  width: '48px',
                  height: '0.5rem',
                  borderRadius: '0.25rem',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    width: `${percs.sucessos}%`,
                    height: '100%',
                    backgroundColor: '#28a745',
                  }}
                  title={`${percs.sucessos}% processados com sucesso`}
                ></div>
                <div
                  style={{
                    width: `${percs.erros}%`,
                    height: '100%',
                    backgroundColor: '#a74528',
                  }}
                  title={`${percs.erros}% com erro no processamento`}
                ></div>
                <div
                  style={{
                    width: `${percs.naoProcessados}%`,
                    height: '100%',
                    backgroundColor: 'lightgray',
                  }}
                  title={`${percs.naoProcessados}% em processamento / não processados`}
                ></div>
              </div>
              <span
                style={{
                  width: '3rem',
                  color: '#a74528',
                  textAlign: 'end',
                }}
              >
                {percs.erros}%
              </span>
            </div>
            <div
              style={{
                fontSize: '12px',
                color: 'var(--tc-color-gray-400)',
              }}
            >
              {row.estado}
            </div>
          </div>
        );
      },
    },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            {['concluida', 'erro'].includes(row.estado) &&
              (row.qtd_erros > 0 ||
                (row.qtd_clientes > 0 && row.qtd_sucessos == 0)) && (
                <Botao
                  variant="none-primary"
                  icon={mdiSync}
                  onClick={() => {
                    retryMutator.mutate({ id: row.id });
                  }}
                />
              )}
          </HBox>
        );
      },
    },
  ];

  const campanhaProgressQuery = useQuery(
    ['campanha_mensagem_progress'],
    async () => {
      const res = await fetch('/api/campanha_mensagem/progress');
      return res.json();
    },
    {
      staleTime: 20000,
      refetchInterval: data => {
        if (!data || data.result.length === 0) {
          return 10000;
        }
        return 2000;
      },
      onSuccess: data => {
        if (!data) return;

        for (const res of data.result) {
          if (['concluida', 'erro'].includes(res.estado)) {
            console.log('campanha ' + res.id + ' concluída');
            queryList.refetch();
            return;
          }
        }
      },
    },
  );

  const queryList = useInfiniteQuery({
    queryKey: ['campanha_mensagem'],
    queryFn: async ({ pageParam }) => {
      const where: Record<string, any> = {};

      if (filterCanais.length > 0) {
        where['canal.id'] = ['in', filterCanais];
      }

      const params = new URLSearchParams({
        txt: search,
        c: pageParam,
        fi: JSON.stringify(where),
      });

      return await axios.get<ResponseList>(
        `/api/campanha_mensagem?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const canalQuery = useQuery(['canal'], async () => {
    const res = await fetch('/api/canal/box');
    return res.json();
  });

  const msgProgramadaQuery = useQuery(['mensagem_programada'], async () => {
    const res = await fetch('/api/mensagem_programada/box');
    return res.json();
  });

  const handleChange = useCallback(async () => { }, []);

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setImportCompleted(false);
    setEditObject({});
  };

  const handleCampanhaCompleted = () => {
    // setNewObj(null)
    setImportCompleted(true);
    console.log('handleCampanhaCompleted');
    queryList.refetch();

    setEditObject(null);
    toast('A campanha foi iniciada com sucesso!', {
      toastId: 'success',
      position: 'top-center',
      type: 'success',
      autoClose: 5000,
    });
  };

  const handleCampanhaError = () => {
    setImportCompleted(true);
    console.log('handleCampanhaError');
    queryList.refetch();

    setEditObject(null);
    toast('Ocorreu um erro ao tentar iniciar a campanha. Tente novamente mais tarde.', {
      toastId: 'warning',
      position: 'top-center',
      type: 'warning',
      autoClose: 5000,
    });
  };

  const retryMutator = useMutation(
    (data: any) => {
      setShowModalRetry(true);
      return fetch(`/api/campanha_mensagem/${data.id}/retry_failed`, {
        method: 'POST',
      }).then(res => res.json());
    },
    {
      onSettled: () => {
        queryList.refetch();
        retryMutator.reset();
      },
    },
  );

  const execute = async (data: any) => { };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;
  const editMode = !!editObject?.id && editObject.id > 0;

  return (
    <LayoutUsuario>
      <Modal
        title="Executando campanha"
        show={showModalRetry}
        onClose={() => setShowModalRetry(false)}
        onConfirm={() => setShowModalRetry(false)}
      >
        <p>Sua solicitação agora está sendo processada.</p>
        <p>Acompanhe o progresso na tela de campanhas.</p>
      </Modal>

      <Modal
        instanceId='modal-campanha'
        title="Nova campanha"
        show={editObject != null}
        onClose={() => setEditObject(null)}
        hideControls
        ignoreOverlayClick
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            execute(editObject);
          }}
        >
          <FormCampanha onCompleted={handleCampanhaCompleted} onError={handleCampanhaError} />
        </form>
      </Modal>

      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <Grid>
          <GridItem sm={12} md={4}>
            <VBox style={{ flex: 1 }}>
              <Titulo>Campanhas</Titulo>
              <Descricao>
                Dispare notificações em massa para seus clientes.
              </Descricao>
            </VBox>
          </GridItem>
          <GridItem sm={12} md={4}>

            <div style={{ flex: 1, textAlign: 'center' }}>
              <Tutorial ytId="aZkxt19b51Q" ytSi="2ETUQCiLd1g3X4N_" />
            </div>
          </GridItem>
          <GridItem sm={12} md={4}>

            <HBox gap="12px" className={styles.headerAcoes} style={{ flex: 1 }}>
              <Botao
                variant="primary"
                icon={mdiPlus}
                onClick={handleOpenModalNewObject}
              >
                Nova
              </Botao>
            </HBox>
          </GridItem>
        </Grid>

        <Grid>
          <GridItem sm={12}>
            <FiltroCanais value={filterCanais} onChange={setFilterCanais} />
          </GridItem>
        </Grid>

        <div>
          <Botao variant="primary" onClick={() => queryList.refetch()}>
            Aplicar
          </Botao>
        </div>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
