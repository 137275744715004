import Icon from '@mdi/react';
import styles from './Botao.module.css';
import HBox from './layout/HBox';
import { Link } from 'react-router-dom';

export type BotaoVariants =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'
  | 'transparent'
  | 'none-primary'
  | 'none-secondary'
  | 'none-success'
  | 'none-info'
  | 'none-warning'
  | 'none-danger'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-success'
  | 'outline-info'
  | 'outline-warning'
  | 'outline-danger'
  | 'none-tutorial'
  | 'tutorial'
  | 'outline-tutorial';

const Botao = ({
  children,
  variant = 'primary',
  onClick,
  icon,
  iconProps,
  disabled,
  type = 'button',
  href,
  className,
  ...props
}: Readonly<{
  children?: React.ReactNode;
  variant?: BotaoVariants;
  onClick?: (e: any) => void;
  icon?: string;
  iconProps?: { [key: string]: any };
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  href?: string;
  className?: string;
  [key: string]: any;
}>) => {
  if (href && disabled)
    return (
      <div
        className={[
          styles.button,
          styles[variant],
          styles.disabled,
          className,
        ].join(' ')}
        {...props}
      >
        <HBox>
          {icon && <Icon path={icon} size="24px" {...(iconProps ?? {})}></Icon>}
          {children && (
            <span style={{ width: '100%', textWrap: 'nowrap' }}>
              {children}
            </span>
          )}
        </HBox>
      </div>
    );

  if (href)
    return (
      <Link
        to={href}
        className={[
          styles.button,
          styles.link,
          styles[variant],
          disabled ? styles.disabled : null,
          className,
        ].join(' ')}
        onClick={onClick}
        type={type}
        {...props}
      >
        <HBox>
          {icon && <Icon path={icon} size="24px" {...(iconProps ?? {})}></Icon>}
          {children && (
            <span style={{ width: '100%', textWrap: 'nowrap' }}>
              {children}
            </span>
          )}
        </HBox>
      </Link>
    );
  return (
    <button
      className={[
        styles.button,
        styles[variant],
        disabled ? styles.disabled : null,
        className,
      ].join(' ')}
      onClick={onClick}
      disabled={disabled ?? false}
      type={type}
      {...props}
    >
      <HBox>
        {icon && <Icon path={icon} size="24px" {...(iconProps ?? {})}></Icon>}
        {children && (
          <span style={{ width: '100%', textWrap: 'nowrap' }}>{children}</span>
        )}
      </HBox>
    </button>
  );
};
export default Botao;
