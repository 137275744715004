'use client';

import { useEffect, useState } from 'react';
import Cabecalho from '../../components/tipografia/Cabecalho';
import Texto from '../../components/tipografia/Texto';
import CampoTexto from '../../components/Formulario/CampoTexto';
import VBox from '../../components/layout/VBox';
import Botao from '../../components/Botao';
import HBox from '../../components/layout/HBox';
import CampoCheckbox from '../../components/Formulario/CampoCheckbox';
import { mdiPauseCircle, mdiPlayCircle } from '@mdi/js';
import useWindowSize from '../../lib/useWindowSize';
import { AnimatedBackground } from './AnimatedBackground';
import { api } from '../../lib/apiCall';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../lib/useAuth';
import CampoSenha from '../../components/Formulario/CampoSenha';
import AlertBox from '@/components/layout/AlertBox';

const Page = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const s = new URLSearchParams(window.location.search);
  const redirPath = s.get('r') ?? '/chat';

  console.log('redir path', redirPath);

  useEffect(() => {
    if (user) navigate(redirPath);
  }, [user, navigate, redirPath]);

  const [width, height, mobile] = useWindowSize();

  const [pause, setPause] = useState(false);

  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [lembrar, setLembrar] = useState(true);

  const [loginError, setLoginError] = useState<string | null>(null);
  // const [success, setSuccess] = useState<boolean>(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoginError(null);

    try {
      const result = await api.post('/api/login', {
        username: usuario,
        password: senha,
      });
      setLoginError('');
      console.log('result', result);

      if (result.data.ok) navigate(redirPath);
      else setLoginError('Usuário ou senha inválidos');
    } catch (e) {
      setLoginError('Usuário ou senha inválidos');
    }
    // const result = await doLogin(usuario, senha)
    // if (!result) {
    //   setLoginError('Usuário ou senha inválidos')
    // }
  };

  // useEffect(() => {
  //   me().then((user: any) => {
  //     console.log('user', user)
  //     if (user) {
  //       return redirect("/");
  //     }
  //   })
  // }, [])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10rem',
      }}
    >
      {!mobile && (
        <img
          src="/logo-terachat-branco.png"
          alt="Logo TeraChat"
          style={{ width: '25%' }}
        />
      )}
      <div
        style={{
          minWidth: '400px',
          width: mobile ? '95%' : '25%',
          backgroundColor: 'white',
          borderRadius: '40px',
          padding: '40px',
          gap: '32px',
        }}
      >
        <VBox>
          {mobile && (
            <div style={{ textAlign: 'center' }}>
              <img
                src="/logo-terachat-branco.png"
                alt="Logo TeraChat"
                style={{ width: '30%' }}
              />
            </div>
          )}
          <Cabecalho>Bem-vindo de volta!</Cabecalho>
          <Texto centralizado>Acesse a plataforma de chat</Texto>

          <form onSubmit={handleSubmit}>
            <VBox>
              <CampoTexto
                name="username"
                tabIndex={1}
                autoFocus
                label="Usuário"
                placeholder="Insira seu usuário"
                type="text"
                value={usuario}
                onChange={setUsuario}
                required
              />

              <CampoSenha
                name="password"
                tabIndex={2}
                label="Senha"
                placeholder="Insira sua senha"
                type="text"
                value={senha}
                onChange={setSenha}
                required
              />

              <HBox>
                <CampoCheckbox
                  text="Lembrar meus dados"
                  value={lembrar}
                  onChange={setLembrar}
                  tabIndex={3}
                />
              </HBox>
              {loginError && <AlertBox variant="danger">{loginError}</AlertBox>}
              <Botao
                style={{ width: '100%' }}
                type="submit"
                variant="primary"
                tabIndex={4}
              >
                Entrar
              </Botao>
            </VBox>
          </form>
        </VBox>
      </div>
      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        {!pause && (
          <Botao
            variant="transparent"
            onClick={() => {
              setPause(true);
            }}
            icon={mdiPauseCircle}
            tabIndex={0}
          >
            Pausar animação
          </Botao>
        )}
        {pause && (
          <Botao
            variant="transparent"
            onClick={() => {
              setPause(false);
            }}
            icon={mdiPlayCircle}
            tabIndex={0}
          >
            Continuar animação
          </Botao>
        )}
      </div>
      <AnimatedBackground width={width} height={height} pause={pause} />
    </div>
  );
};

export default Page;
