import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback, useEffect } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import {
  mdiEmail,
  mdiLoading,
  mdiMagnify,
  mdiPen,
  mdiPlus,
  mdiTrashCan,
  mdiWrench,
} from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation } from 'react-query';
import axios from 'axios';
import { useDebounce } from '../chat/useDebounce';
import IconeCanal from '../../components/layout/IconeCanal';
import { CanalDto } from '@tera/shared/src/dto/CanalDto';
import Modal from '../../components/layout/Modal';
import FormCanal from '../../components/modelos/FormCanal';
import { toast } from 'react-toastify';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import BotaoSeletor from '../../components/BotaoSeletor';
import Grid, { GridItem } from '@/components/layout/Grid';

type PageProps = {};

type ResponseList = {
  status: number;
  ok?: boolean;
  result: CanalDto[];
  total: number;
  cursor: string | number | null;
};

const Page = ({ }: PageProps) => {
  const [editObject, setEditObject] = React.useState<CanalDto | null>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const [showModalLogin360, setShowModalLogin360] =
    React.useState<boolean>(false);

  const [showModalNew360, setShowModalNew360] = React.useState<boolean>(false);

  const baseUrl = window.location.origin;
  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      if (event.origin !== baseUrl || typeof event.data === 'object') {
        return;
      }
      const { data } = event;
      const redirectUrl = `${data}`;
      window.location.search = redirectUrl;
    };

    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const openLoginD360Window = () => {
    const windowFeatures =
      'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
    const partnerId = import.meta.env.VITE_D360_PARTNER_ID;
    const redirectUrl = decodeURIComponent(window.location.origin + '/onboard');

    // Open the new window with the specified URL
    window.open(
      'https://hub.360dialog.com/dashboard/app/' +
      partnerId +
      '/permissions?next=login&redirect_url=' +
      redirectUrl,
      'integratedOnboardingWindow',
      windowFeatures,
    );
  };

  const openNewD360Window = () => {
    const windowFeatures =
      'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
    const partnerId = import.meta.env.VITE_D360_PARTNER_ID;
    const redirectUrl = decodeURIComponent(window.location.origin + '/onboard');

    // Open the new window with the specified URL
    window.open(
      'https://hub.360dialog.com/dashboard/app/' +
      partnerId +
      '/permissions?redirect_url=' +
      redirectUrl,
      'integratedOnboardingWindow',
      windowFeatures,
    );
  };

  const [whVerifying, setWhVerifying] = React.useState<number | null>(null);

  const whVerifyMutator = useMutation(
    async (id: number) => {
      setWhVerifying(id);
      return await axios.post(
        `/api/canal/${id}/wh-fix`,
        {},
        {
          withCredentials: true,
        },
      );
    },
    {
      onSettled: () => {
        setWhVerifying(null);
        queryList.refetch();
      },
    },
  );

  const receberEmailsMutator = useMutation(
    async (id: number) => {
      return axios.post(
        `/api/canal/${id}/receber-emails`,
        {},
        {
          withCredentials: true,
        },
      );
    },
    {
      onSuccess: () => {
        toast('Rotina executada com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      },
      onError: (err: any) => {
        console.error(err);

        toast('Ocorreu um erro ao tentar verificar os e-mails.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleWhVerify = (id: number) => {
    whVerifyMutator.mutate(id);
  };

  const headers: DataTableHeader[] = [
    {
      label: 'Tipo',
      width: '32px',
      render: (row: any) => {
        return <IconeCanal tipo={row.tipo} style={{ height: '24px' }} />;
      },
    },
    { label: 'Nome', width: '50%', column: 'nome' },
    {
      label: '',
      width: '50%',
      alignRight: true,
      render(row) {
        const webhookCheck = row.webhookCheck;
        if (!webhookCheck.result) {
          return (
            <span
              style={{
                color: 'var(--tc-color-warning)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {webhookCheck.msg}
              {(!webhookCheck.msg ||
                webhookCheck.msg.indexOf('não suportado') === -1) && (
                  <>
                    {whVerifying === row.id && !queryList.isFetching ? (
                      <span>
                        <Icon
                          path={mdiLoading}
                          size="14px"
                          color="var(--tc-color-gray-700)"
                          spin
                        />
                        Verificando...
                      </span>
                    ) : null}
                    {whVerifying !== row.id ? (
                      <Botao
                        variant="outline-primary"
                        size="small"
                        icon={mdiWrench}
                        onClick={() => handleWhVerify(row.id)}
                      >
                        Verificar
                      </Botao>
                    ) : null}
                  </>
                )}
            </span>
          );
        }
        return null;
      },
    },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            {row.tipo === 'email' && !receberEmailsMutator.isLoading && (
              <Botao
                variant="none-info"
                icon={mdiEmail}
                title="Verificar e-mails"
                onClick={() => receberEmailsMutator.mutateAsync(row.id)}
              ></Botao>
            )}
            {row.tipo === 'email' && receberEmailsMutator.isLoading && (
              <Icon
                path={mdiLoading}
                size="14px"
                color="var(--tc-color-gray-700)"
                spin
              />
            )}
            <Botao
              variant="none-primary"
              icon={mdiPen}
              title="Editar"
              onClick={() => setEditObject(row as CanalDto)}
            ></Botao>
            <BotaoPerigoso
              variant="none-danger"
              icon={mdiTrashCan}
              title="Apagar"
              onClick={() => handleCanalDelete(row.id)}
            />
          </HBox>
        );
      },
    },
  ];

  const queryList = useInfiniteQuery({
    queryKey: ['canal', debouncedSearch],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
      });

      return await axios.get<ResponseList>(`/api/canal?${params.toString()}`, {
        withCredentials: true,
      });
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const canalChangeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(`/api/canal/${editObject.id}`, editObject, {
          withCredentials: true,
        });
      } else {
        return await axios.post(`/api/canal`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const canalDeleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/canal/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Canal apagado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar o canal.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleCanalChange = useCallback(async () => {
    await canalChangeMutator.mutate();
  }, [canalChangeMutator]);

  const handleCanalDelete = (id: number) => {
    canalDeleteMutator.mutate(id);
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;

  const editMode = !!editObject?.id && editObject.id > 0;

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleConfirmLogin360 = () => {
    setShowModalLogin360(false);
    openLoginD360Window();
  };

  const handleConfirmNew360 = () => {
    setShowModalNew360(false);
    openNewD360Window();
  };

  const host = `${window.location.protocol}//${window.location.host}`;

  return (
    <LayoutUsuario>
      <Modal
        size="lg"
        title="Novo cadastro - 360Dialog"
        show={showModalNew360}
        onClose={() => setShowModalNew360(false)}
        onCancel={() => setShowModalNew360(false)}
        onConfirm={handleConfirmNew360}
        confirmProps={{ label: 'Entendi. Continuar!' }}
      >
        <div style={{ padding: '16px' }}>
          <VBox gap="16px">
            <Descricao>
              Ao continuar, uma nova janela será aberta para realizar o
              onboarding de seus números de WhatsApp.
            </Descricao>
            <Descricao>
              1. Crie uma conta na 360Dialog. Guarde seu novo usuário e senha,
              pois podem ser utilizados depois para gerenciar os números.
            </Descricao>
            <Descricao>
              2. Siga os passos para autorizar seu WhatsApp Business API.
            </Descricao>
            <Descricao>
              3. Após autorizar, <b>não feche o popup!</b> Nossos sistemas irão
              validar e criar os registros necessários.
            </Descricao>
            <Descricao>
              4. Pronto! Seus números estarão ativos para uso!
            </Descricao>
          </VBox>
        </div>
      </Modal>

      <Modal
        size="lg"
        title="Login 360Dialog"
        show={showModalLogin360}
        onClose={() => setShowModalLogin360(false)}
        onCancel={() => setShowModalLogin360(false)}
        onConfirm={handleConfirmLogin360}
        confirmProps={{ label: 'Entendi. Continuar!' }}
      >
        <div style={{ padding: '16px' }}>
          <VBox gap="16px">
            <Descricao>
              Ao continuar, uma nova janela será aberta para configurar seus
              números de WhatsApp.
            </Descricao>
            <Descricao>1. Entre com seu login da 360Dialog.</Descricao>
            <Descricao>
              2. verifique se todos os números que deseja autorizar estão na
              lista. Se estiverem, clique em &quot;Authorize&quot;. Se não,
              marque os números desejados na combobox.
            </Descricao>
            <Descricao>
              3. Após autorizar, <b>não feche o popup!</b> Nossos sistemas irão
              validar e criar os registros necessários.
            </Descricao>
            <Descricao>
              4. Pronto! Seus números estarão ativos para uso!
            </Descricao>
          </VBox>
        </div>
      </Modal>

      <Modal
        ignoreOverlayClick
        size="lg"
        title={editMode ? 'Editar canal' : 'Novo canal'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleCanalChange}
      >
        <FormCanal
          host={host}
          value={editObject as CanalDto}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <Grid>
          <GridItem sm={12} md={6}>
            <VBox>
              <Titulo>Canal</Titulo>
              <Descricao>Detalhes do Canal</Descricao>
            </VBox>
          </GridItem>
          <GridItem sm={12} md={6}>
            <HBox gap="12px" className={styles.headerAcoes}>
              <BotaoSeletor
                variant="primary"
                options={[
                  {
                    id: 'telegram',
                    label: (
                      <HBox>
                        <IconeCanal tipo="telegram" style={{ height: '24px' }} />{' '}
                        Adicionar telegram
                      </HBox>
                    ),
                  },
                  {
                    id: 'email',
                    label: (
                      <HBox>
                        <IconeCanal tipo="email" style={{ height: '24px' }} />{' '}
                        Adicionar e-mail
                      </HBox>
                    ),
                  },
                  {
                    id: 'voip',
                    label: (
                      <HBox>
                        <IconeCanal tipo="voip" style={{ height: '24px' }} />{' '}
                        Adicionar VoIP
                      </HBox>
                    ),
                  },
                  {
                    id: 'webchat',
                    label: (
                      <HBox>
                        <IconeCanal tipo="webchat" style={{ height: '24px' }} />{' '}
                        Adicionar webchat
                      </HBox>
                    ),
                  },
                  {
                    id: 'wa360-new',
                    label: (
                      <HBox>
                        <IconeCanal tipo="whatsapp" style={{ height: '24px' }} />{' '}
                        Adicionar whatsapp
                      </HBox>
                    ),
                  },
                  { id: 'hr', label: <hr /> },
                  {
                    id: 'wa360',
                    label: (
                      <HBox>
                        <IconeCanal tipo="whatsapp" style={{ height: '24px' }} />{' '}
                        Gerenciar whatsapp
                      </HBox>
                    ),
                  },
                ]}
                onChange={v => {
                  if (v === 'telegram') {
                    setEditObject({
                      tipo: 'telegram',
                      chave_api: '',
                    } as CanalDto);
                  } else if (v === 'email') {
                    setEditObject({ tipo: 'email', chave_api: '' } as CanalDto);
                  } else if (v === 'voip') {
                    setEditObject({ tipo: 'voip', chave_api: '' } as CanalDto);
                  } else if (v === 'wa360') {
                    setShowModalLogin360(true);
                  } else if (v === 'wa360-new') {
                    setShowModalNew360(true);
                  } else if (v === 'webchat') {
                    setEditObject({ tipo: 'webchat', chave_api: '' } as CanalDto);
                  }
                }}
              >
                Gerenciar&nbsp;canais
              </BotaoSeletor>
            </HBox>
          </GridItem>
        </Grid>

        {/* Filtros */}
        <HBox>
          <CampoTexto
            value={search}
            onChange={setSearch}
            prepend={
              <Icon
                path={mdiMagnify}
                size={1}
                color="var(--tc-color-gray-500)"
              />
            }
            placeholder="Pesquisar"
          />
        </HBox>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
