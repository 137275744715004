import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import axios from 'axios';
import { useQuery } from 'react-query';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import CampoSenha from '../../components/Formulario/CampoSenha';
import { DiaExpediente } from './DiaExpediente';
import AlertBox from '@/components/layout/AlertBox';
import CampoMemo from '@/components/Formulario/CampoMemo';
import { CampoPersonalizacao } from '@/components/Formulario/CampoPersonalizacao';
import CampoDinheiro from '@/components/Formulario/CampoDinheiro';

export const FormProduto = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const qCustomize = useQuery('produto/meta', async () => {
    const res = await axios.get('/api/produto/meta', {
      withCredentials: true,
    });

    return res.data.result;
  });

  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <GridItem sm={12} md={4}>
        <CampoTexto
          instanceId='nome'
          value={value?.nome ?? ''}
          onChange={v => onChange({ ...value, nome: v })}
          label="Nome"
          error={fieldErrors?.nome}
        />
      </GridItem>
      <GridItem sm={12} md={8}>
        <CampoTexto
          instanceId='descricao'
          value={value?.descricao ?? ''}
          onChange={v => onChange({ ...value, descricao: v })}
          label="Descrição curta"
          error={fieldErrors?.descricao}
        />
      </GridItem>
      <GridItem sm={12}>
        <CampoDinheiro
          instanceId='valor'
          value={value?.valor ?? 0}
          onChange={v => onChange({ ...value, valor: v })}
          label="Valor"
          error={fieldErrors?.valor}
        />
      </GridItem>

      <GridItem sm={12}>
        <CampoMemo
          instanceId='obs'
          value={value?.obs ?? ''}
          onChange={v => onChange({ ...value, obs: v })}
          label="Observações"
          error={fieldErrors?.obs}
        />
      </GridItem>

      {qCustomize.data?.length > 0 && (
        <GridItem sm={12}>
          <div style={{ marginTop: '12px' }}>Campos personalizados</div>
          <Grid>
            {qCustomize.data.map((it: any, idx: number) => (
              <GridItem
                sm={12}
                md={
                  qCustomize.data?.length - 1 === idx && idx % 2 === 0 ? 12 : 6
                }
              >
                <CampoPersonalizacao
                  label={it.rotulo}
                  type={it.tipo}
                  value={
                    value?.metadados?.[it.nome] != null
                      ? value?.metadados?.[it.nome]
                      : it.valor_padrao ?? ''
                  }
                  onChange={(v: any) => {
                    onChange({
                      ...value,
                      metadados: {
                        ...value.metadados,
                        [it.nome]: v,
                      },
                    });
                  }}
                  // options={it.opcoes?.map((it: any) => ({ id: it, label: it }))}
                  error={fieldErrors?.[`metadados.${it.nome}`]}
                />
              </GridItem>
            ))}
          </Grid>
        </GridItem>
      )}
    </Grid>
  );
};
