import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import HBox from '../../components/layout/HBox';
import Skeleton from '../../components/layout/Skeleton';
import { translateFilter } from './util';
import GaugeChart from '@/components/layout/GaugeChart';
import { format, parse } from 'date-fns';
import { ptBR } from "date-fns/locale";

type Props = {
  colorPalette: string[];
  filterCanal: number[];
  periodo: [string, string, string];
};

export const GraficoNPS = ({
  colorPalette,
  periodo,
  filterCanal,
}: Props) => {
  const q = useQuery(
    ['nps', periodo, filterCanal],
    async () => {
      const qs = new URLSearchParams();

      const f = translateFilter({
        periodo,
        canal_id: filterCanal,
      });

      qs.append('facts', 'avg_score');
      qs.append('dimensions', 'data_criacao_mes');
      qs.append('filter', f);

      const res = await axios.get(
        '/api/relatorios/chat_pontuacao?' + qs.toString(),
      );

      console.log('res', res.data.result);
      return res.data.result;
    },
    { refetchOnWindowFocus: false },
  );


  const dados = q.data ? q.data.reduce((acc: any, row: any) => {
    if (row.avg_score == null) return acc;

    const mes = row.data_criacao_mes.substring(0, 7);
    if (!acc[acc.length - 1] || acc[acc.length - 1].mes != mes)
      acc.push({
        mes,
        avg_score: row.avg_score,
      });
    return acc;
  }, [])
    : [];


  console.log("nps dados", dados)


  const [foco, setFoco] = useState<string | null>(null);

  // const [abrirModal, setAbrirModal] = useState(false);

  const CustomTooltip = useCallback(
    ({ active, payload, label, separator }: any) => {
      if (active && payload && payload.length) {
        const v = payload.find((p: any) => p.name === label);
        return (
          <div
            style={{
              backgroundColor: '#fff8',
              border: '1px solid #ccc',
              padding: '0.5rem',
            }}
          >
            {JSON.stringify(label)}
            {label != '0' && (
              <p>
                <b>{label}</b>
              </p>
            )}
            <p>
              {label}
              {separator}
              {v}
            </p>
          </div>
        );
      }

      return null;
    },
    [foco],
  );

  const renderDot = ({
    cx,
    cy,
    fill,
    stroke,
    strokeWidth,
    strokeOpacity,
    dataKey,
  }: any) => {
    const r = 7;
    return (
      <svg
        x={cx - r * 4}
        y={cy - r}
        width={r * 8}
        height={r * 2}
        viewBox={`0 0 ${r * 8} ${r * 2}`}
        onMouseEnter={() => setFoco(dataKey)}
        onMouseLeave={() => setFoco(null)}
      >
        <rect x={0} y={0} width={r * 8} height={r * 2} fill="transparent" />
        <circle
          cx={r * 4}
          cy={r}
          r={r}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
          opacity={!foco || dataKey === foco ? strokeOpacity : 0.2}
        />
      </svg>
    );
  };

  const handleMouseEnter = (o: any) => {
    const { dataKey } = o;
    setFoco(dataKey);
  };

  const handleMouseLeave = () => {
    setFoco(null);
  };

  if (q.isFetching) {
    return <Skeleton format="box" />;
  }

  // if (!q.isFetching) {
  //   return (
  //     <div>
  //       <pre>{JSON.stringify(q.data, null, 2)}</pre>
  //     </div>
  //   );
  // }

  return (
    <div style={{ width: '100%' }}>
      {q.isSuccess && dados.length === 0 && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Nenhum dado encontrado</p>
        </div>
      )}
      {q.isSuccess && dados?.length === 1 && (
        <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', flexDirection: 'column' }}>
          <GaugeChart value={dados[0].avg_score} />

          <div style={{ textAlign: 'center', width: '100%' }}>
            {dados[0].avg_score >= 75 && (
              <span
                style={{
                  fontSize: '26px',
                  fontWeight: 'bold',
                  color: 'var(--tc-color-success)',
                }}
              >Excelente!</span>
            )}

            {dados[0].avg_score < 75 && dados[0].avg_score >= 50 && (
              <span
                style={{
                  fontSize: '26px',
                  fontWeight: 'bold',
                  color: 'var(--tc-color-info)',
                }}
              >Bom!</span>
            )}
            {dados[0].avg_score < 50 && dados[0].avg_score >= 25 && (
              <span
                style={{
                  fontSize: '26px',
                  fontWeight: 'bold',
                  color: 'var(--tc-color-warning)',
                }}
              >Regular</span>
            )}
            {dados[0].avg_score < 25 && (
              <span
                style={{
                  fontSize: '26px',
                  fontWeight: 'bold',
                  color: 'var(--tc-color-danger)',
                }}
              >Pode melhorar!</span>
            )}
          </div>
          <p style={{ fontStyle: 'italic', color: "var(--tc-color-gray-500)" }}>mês: {format(parse(dados[0].mes, 'yyyy-MM', new Date()), 'MMM/yyyy', { locale: ptBR })}</p>
        </div>
      )}
      {q.isSuccess && dados?.length > 1 && (
        <HBox>
          <ResponsiveContainer height={200}>
            <LineChart
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}
              data={dados}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="mes" interval={1} />
              <YAxis interval={1} />
              <Tooltip
                cursor={{ fill: '#8882' }}
                itemSorter={(item: any) => {
                  return -item.value as number;
                }}
                itemStyle={{ padding: 0, fontSize: '0.9rem' }}
                labelStyle={{ fontWeight: 'bold' }}
              // content={<CustomTooltip />}
              />
              <Legend
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                formatter={(v, e: any, i) => {
                  const { dataKey } = e;

                  return (
                    <span
                      style={{
                        color: colorPalette[i % colorPalette.length],
                        opacity: !foco || foco === dataKey ? 1 : 0.2,
                        fontWeight:
                          foco && foco === dataKey ? 'bold' : 'normal',
                      }}
                    >
                      {v}
                    </span>
                  );
                }}
              />


              <Line
                type="monotone"
                // stackId="a"
                key={'avg_score'}
                dataKey={`avg_score`}
                name={'Pontuação média'}
                stroke={colorPalette[0]}
                strokeOpacity={
                  !foco || foco === `avg_score` ? 1 : 0.2
                }
                strokeDasharray={
                  !foco || foco === `avg_score` ? `0` : `5 5`
                }
                activeDot={renderDot}
              />

            </LineChart>
          </ResponsiveContainer>
        </HBox>
      )}
    </div>
  );
};
