import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback, useMemo } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiCalendar, mdiCalendarOutline, mdiCheck, mdiCheckBold, mdiClock, mdiClockOutline, mdiLoading, mdiMagnify, mdiPen, mdiPlus, mdiTrashCan } from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import Grid, { GridItem } from '../../components/layout/Grid';
import { parseISO, format, addDays, isBefore, isAfter } from 'date-fns';
import { FormAtividade } from './FormAtividade';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type CardAtividadeProps = {
  value: any;
  onDone: () => void;
  onEdit: () => void;
  onRemove: () => void;
}

const atividadeEstadoEnum: Record<string, string> = {
  pendente: 'Pendente',
  concluida: 'Concluída',
  cancelada: 'Cancelada'
}
const CardAtividade = ({
  value, onDone, onEdit, onRemove
}: CardAtividadeProps) => {
  return (
    <div className='card-atividade' style={{ border: '1px solid var(--tc-color-gray-300)', borderRadius: '14px', padding: '4px 10px', margin: '4px' }}>
      <HBox stretch>
        <div style={{ display: 'flex', alignItems: 'center' }}><Icon path={mdiClockOutline} size={1} color='var(--tc-color-gray-300' />{value?.titulo}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}><Icon path={mdiCalendarOutline} size={1} color='var(--tc-color-gray-300' /> {value?.data ? new Date(value.data).toLocaleDateString() : ''}</div>
      </HBox>
      <HBox stretch>
        <div>
          {format(value?.data_evento, 'HH:mm')}
        </div>
        <div>
          {value?.local}
        </div>
      </HBox>
      <HBox stretch>
        <div>
          {atividadeEstadoEnum[value?.estado]}
        </div>
        <div>
          <Botao variant='none-primary' size='sm' icon={mdiCheckBold} title="Marcar como realizada" onClick={onDone} />
          <Botao variant='none-info' size='sm' icon={mdiPen} title="Editar" onClick={onEdit} />
          <BotaoPerigoso variant='none-danger' size='sm' icon={mdiTrashCan} buttonProps={{ title: 'Apagar' }} onClick={onRemove} />
        </div>
      </HBox>


    </div>
  )
}

const today = new Date().toISOString().split('T')[0];
const tomorrow = addDays(new Date(), 1).toISOString().split('T')[0];

type Props = {};

const Page = ({ }: Props) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const [atividadesByDate, setAtividadesByDate] = React.useState<any>({});

  const queryList = useQuery({
    queryKey: ['atividade'],
    queryFn: async () => {
      const filter: Record<string, any> = {
        estado: ['eq', 'pendente'],
      };

      const params = new URLSearchParams({
        txt: debouncedSearch,
        fi: JSON.stringify(filter),
      });

      const r = await axios.get<ResponseList>(
        `/api/atividade?${params.toString()}`,
        {
          withCredentials: true,
        },
      );

      return r.data;
    },
    onSuccess: (data) => {
      const _atividadesByDate = data.result.reduce((acc, atividade) => {
        const date = new Date(atividade.data_evento).toISOString().split('T')[0];

        // before
        if (isBefore(date, today)) {
          if (!acc['before']) acc['before'] = [];
          acc['before'].push(atividade);
        }

        // today
        if (date === today) {
          if (!acc['today']) acc['today'] = [];
          acc['today'].push(atividade);
        }

        // tomorrow
        if (date === tomorrow) {
          if (!acc['tomorrow']) acc['tomorrow'] = [];
          acc['tomorrow'].push(atividade);
        }

        // after
        if (isAfter(date, tomorrow)) {
          if (!acc['after']) acc['after'] = [];
          acc['after'].push(atividade);
        }

        return acc;
      }, {} as Record<string, any[]>)

      setAtividadesByDate(_atividadesByDate);
    }
  });

  const changeMutator = useMutation(
    async (data: any) => {
      if (!data) return;

      setFieldErrors(null);
      if (data.id) {
        return await axios.put(
          `/api/atividade/${data.id}`,
          data,
          {
            withCredentials: true,
          },
        );
      } else {
        return await axios.post(`/api/atividade`, data, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: (data, vars) => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
        toast(vars.id > 0 ? 'Atividade alterada com sucesso' : 'Atividade adicionada com sucesso', {
          toastId: 'sucsess',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/atividade/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Atividade apagada com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar a atividade.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate(editObject);
  }, [changeMutator]);

  const handleDone = useCallback(async (id: number) => {
    await changeMutator.mutate({ id, estado: 'concluida' });
  }, [changeMutator]);

  const handleRemove = useCallback(async (id: number) => {
    await deleteMutator.mutate(id);
  }, [deleteMutator]);

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({});
  };

  return (
    <LayoutUsuario>
      <Modal
        instanceId='modal-atividade'
        ignoreOverlayClick
        title={editObject?.id > 0 ? 'Editar atividade' : 'Nova atividade'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormAtividade
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>

      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Minhas atividades</Titulo>
            <Descricao>
              Gerencie aqui suas atividades.
            </Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Nova
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <Grid>
          <GridItem sm={12} lg={6}>
            <CampoTexto
              prepend={
                <Icon
                  path={mdiMagnify}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Pesquisar"
              value={search}
              onChange={setSearch}
            />
          </GridItem>
        </Grid>

        <div>
          <Botao variant="primary" onClick={() => queryList.refetch()}>
            Aplicar
          </Botao>
        </div>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryList.data?.result?.length ?? '0'}{' '}
            resultados.
          </div>
        )}

        <Grid style={{ height: '100%' }}>
          <GridItem sm={12} md={6} lg={3}>
            <div data-testid="atividades-atrasadas" style={{
              height: '100%',
              width: '100%',
              border: '1px solid var(--tc-color-warning-300)',
              borderTop: '7px solid var(--tc-color-warning-300)',
              borderRadius: '14px'
            }}>
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Atrasados</div>

              {atividadesByDate['before']?.map((atividade: any) => (
                <CardAtividade
                  value={atividade}
                  onDone={() => handleDone(atividade.id)}
                  onEdit={() => setEditObject({ ...atividade })}
                  onRemove={() => handleRemove(atividade.id)}
                />
              ))}
            </div>
          </GridItem>
          <GridItem sm={12} md={6} lg={3}>
            <div data-testid="atividades-hoje" style={{
              height: '100%',
              width: '100%',
              border: '1px solid var(--tc-color-success-300)',
              borderTop: '7px solid var(--tc-color-success-300)',
              borderRadius: '14px'
            }}>
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Hoje</div>

              {atividadesByDate['today']?.map((atividade: any) => (
                <CardAtividade
                  value={atividade}
                  onDone={() => handleDone(atividade.id)}
                  onEdit={() => setEditObject({ ...atividade })}
                  onRemove={() => handleRemove(atividade.id)}
                />
              ))}
            </div>

          </GridItem>
          <GridItem sm={12} md={6} lg={3}>
            <div data-testid="atividades-amanha" style={{
              height: '100%',
              width: '100%',
              border: '1px solid var(--tc-color-info-300)',
              borderTop: '7px solid var(--tc-color-info-300)',
              borderRadius: '14px'
            }}>
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Amanhã</div>

              {atividadesByDate['tomorrow']?.map((atividade: any) => (
                <CardAtividade
                  value={atividade}
                  onDone={() => handleDone(atividade.id)}
                  onEdit={() => setEditObject({ ...atividade })}
                  onRemove={() => handleRemove(atividade.id)}
                />
              ))}
            </div>

          </GridItem>
          <GridItem sm={12} md={6} lg={3}>
            <div data-testid="atividades-depois" style={{
              height: '100%',
              width: '100%',
              border: '1px solid var(--tc-color-gray-300)',
              borderTop: '7px solid var(--tc-color-gray-300)',
              borderRadius: '14px'
            }}>
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Depois</div>

              {atividadesByDate['after']?.map((atividade: any) => (
                <CardAtividade
                  value={atividade}
                  onDone={() => handleDone(atividade.id)}
                  onEdit={() => setEditObject({ ...atividade })}
                  onRemove={() => handleRemove(atividade.id)}
                />
              ))}
            </div>
          </GridItem>
        </Grid>

      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
