import { mdiSchool } from "@mdi/js"
import HBox from "./layout/HBox"
import Modal from "./layout/Modal"
import Subtitulo from "./tipografia/Subtitulo"
import Icon from "@mdi/react"

type Props = {
    ytId: string,
    ytSi: string,
    show: boolean,
    onClose: () => void
}

export const ModalTutorialYT = ({ ytId, ytSi, show = false, onClose }: Props) => {
    return (
        <Modal
            title={
                <Subtitulo>
                    <HBox style={{ justifyContent: "center" }}><Icon path={mdiSchool} size="48px" color="var(--tc-color-gray-300)" />
                        <span>Tutorial</span>
                    </HBox>
                </Subtitulo>
            }
            size='lg'
            show={show}
            onClose={onClose}
            hideControls
        >

            <div style={{ textAlign: 'center' }}>
                <iframe
                    width="100%"
                    height="450"
                    src={`https://www.youtube.com/embed/${ytId}?controls=1&modestbranding=1&rel=0&autoplay=1`}
                    title="YouTube video player"
                    style={{ border: 'none' }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen></iframe>
            </div>
        </Modal>
    )
}