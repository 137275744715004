import React from 'react';
import styles from './CampoNumero.module.css';
import stylesTexto from './CampoTexto.module.css';
import 'cleave.js/dist/addons/cleave-phone.br';
import Cleave from 'cleave.js/react';

type CampoNumeroProps = {
  label?: string;
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  props?: any;
  mostrarSeparadorMilhar?: boolean;
  decimais?: number;
  somentePositivos?: boolean;
  disabled?: boolean;
  error?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  noBorder?: boolean;
  instanceId?: string;
};

const CampoNumero = ({
  label,
  value,
  placeholder,
  onChange,
  mostrarSeparadorMilhar,
  decimais,
  somentePositivos,
  disabled,
  error,
  prepend,
  append,
  noBorder,
  instanceId,
  ...props
}: CampoNumeroProps) => {
  return (
    <div className={styles.container}>
      <label>{label}</label>
      <div
        className={[
          stylesTexto.container,
          error ? stylesTexto.error : null,
        ].join(' ')}
        style={{ border: noBorder ? 'none' : undefined }}
      >
        {prepend && <div className={stylesTexto.prepend}>{prepend}</div>}

        <Cleave
          {...props}
          id={instanceId}
          type="tel"
          value={value}
          // guide={false}
          options={{
            numeral: true,
            numeralThousandsGroupStyle: mostrarSeparadorMilhar
              ? 'thousand'
              : 'none',
            numeralDecimalScale: decimais ?? 0,
            numeralDecimalMark: ',',
            delimiter: '.',
            numeralPositiveOnly: somentePositivos,
          }}
          onChange={e => onChange && onChange(e.target.rawValue)}
          className={stylesTexto.input}
          style={{ border: noBorder ? 'none' : undefined }}
          disabled={disabled}
        />

        {append && <div className={stylesTexto.append}>{append}</div>}
      </div>
      {error && <div className={stylesTexto.error}>{error}</div>}
    </div>
  );
};

export default CampoNumero;
