import React, { useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import Selfie from '../../components/perfil/Selfie';
import Cropper from 'react-easy-crop';
import Modal from '../layout/Modal';
import Icon from '@mdi/react';
import {
  mdiCamera,
  mdiImageEdit,
  mdiMagnifyMinusOutline,
  mdiMagnifyPlusOutline,
  mdiUpload,
} from '@mdi/js';
import Botao from '../Botao';
import Grid, { GridItem } from '../layout/Grid';
import UsuarioAvatar from '../UsuarioAvatar';
import CampoTexto from '../Formulario/CampoTexto';
import CampoSenha from '../Formulario/CampoSenha';
import CampoSeletor from '../Formulario/CampoSeletor';

type Props = {
  initialValues?: any;
  fieldErrors?: any;
  onSubmit: (values: any) => Promise<any>;
  isSubmitting?: boolean;
};

export const FormPerfil = ({
  initialValues,
  fieldErrors,
  onSubmit,
  isSubmitting,
}: Props) => {
  const [foto, setFoto] = useState(null);
  const [fotoURL, setFotoURL] = useState<string>('/api/auth/me/avatar');
  const [showSelfie, setShowSelfie] = useState(false);

  const [showEditPhoto, setShowEditPhoto] = useState(false);

  const [cropArea, setCropArea] = useState(undefined);
  const [initialCropArea, setInitialCropArea] = useState(undefined);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [value, setValue] = useState({
    nome: '',
    senha: '',
    confirmar_senha: '',
    mostrar_notificacoes: 'chatbot_atendimento',
    foto_dados_crop: {
      position: { x: 0, y: 0, width: 100, height: 100 },
      zoom: 1,
    },
    ...(initialValues ?? {}),
  });

  const onCropComplete = React.useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCropArea(croppedArea);
    },
    [],
  );

  const fotoInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: any) => {
    processImage(e.target.files[0]);
  };

  const processImage = (file: any) => {
    const fotoFile = file;

    Resizer.imageFileResizer(
      fotoFile, // Is the file of the image which will resized.
      1024, // Is the maxWidth of the resized new image.
      768, // Is the maxHeight of the resized new image.
      'PNG', // Is the compressFormat of the resized new image.
      100, // Is the quality of the resized new image.
      0, // Is the degree of clockwise rotation to apply to uploaded image.
      (file: any) => {
        const reader = new FileReader();
        reader.onload = (e1: any) => {
          setFotoURL(e1.target.result as string);
          setFoto(file);
        };
        reader.readAsDataURL(file);
      }, // Is the callBack function of the resized new image URI.
      'file', // Is the output type of the resized new image.
      480, // Is the minWidth of the resized new image.
      320, // Is the minHeight of the resized new image.
    );
  };

  const onClose = () => {
    setFoto(null);
    setFotoURL('');
    setShowSelfie(false);
  };

  const onCancel = () => {
    onClose();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit({ ...value, foto });
  };

  return (
    <>
      <Modal
        show={showSelfie}
        onClose={onClose}
        onCancel={onCancel}
        title="Selfie"
      >
        <Selfie
          onSelfieTaken={(uri: any) => {
            processImage(uri);
            setShowSelfie(false);
          }}
          active={showSelfie}
        />
      </Modal>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Modal
            title="Editar Foto"
            show={showEditPhoto}
            onClose={() => setShowEditPhoto(false)}
            onCancel={() => setShowEditPhoto(false)}
            onConfirm={() => {
              setValue({
                ...value,
                foto_dados_crop: { position: cropArea, zoom },
              });
              setShowEditPhoto(false);
            }}
            confirmProps={{
              label: 'Alterar',
            }}
          >
            <div style={{ position: 'relative', height: '400px' }}>
              <Cropper
                image={fotoURL}
                crop={crop}
                onCropChange={setCrop}
                zoom={zoom}
                onZoomChange={setZoom}
                aspect={1}
                showGrid={true}
                cropShape="round"
                onCropComplete={onCropComplete}
                initialCroppedAreaPercentages={initialCropArea}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>
                <Icon path={mdiMagnifyMinusOutline} size={1} />
              </div>
              <div style={{ flex: 1 }}>
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={e => setZoom(+e.target.value)}
                  style={{ textAlign: 'center', width: '100%' }}
                // classes={{ container: 'slider' }}
                />
              </div>
              <div>
                <Icon path={mdiMagnifyPlusOutline} size={1} />
              </div>
            </div>
          </Modal>

          <GridItem md={4} sm={12}>
            <div
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                height: '100%',
                minHeight: '350px',
                position: 'relative',
              }}
            >
              <UsuarioAvatar imgSrc={fotoURL} />

              {value?.foto_dados_crop && fotoURL && (
                <div
                  style={{
                    position: 'absolute',
                    left: value.foto_dados_crop?.position?.x + '%',
                    top: value.foto_dados_crop?.position?.y + '%',
                    width: value.foto_dados_crop?.position?.width + '%',
                    height: value.foto_dados_crop?.position?.height + '%',
                    border: '4px dashed #8888',
                    borderRadius: '50%',
                  }}
                ></div>
              )}

              <div
                style={{
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: '1rem',
                  left: 0,
                  right: 0,
                  margin: 'auto auto',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  justifyContent: 'center',
                }}
              >
                <Botao
                  variant="secondary"
                  onClick={() => fotoInputRef.current?.click()}
                  icon={mdiUpload}
                >
                  Foto
                </Botao>

                <Botao
                  variant="secondary"
                  onClick={() => setShowSelfie(true)}
                  icon={mdiCamera}
                >
                  Selfie!
                </Botao>

                <Botao
                  variant="secondary"
                  onClick={() => {
                    setInitialCropArea(value.foto_dados_crop?.position);
                    setCropArea(value.foto_dados_crop?.position);
                    setCrop(value.foto_dados_crop?.position ?? { x: 0, y: 0 });
                    setZoom(
                      Number.isSafeInteger(value.foto_dados_crop?.zoom)
                        ? +value.foto_dados_crop?.zoom
                        : 1,
                    );
                    setShowEditPhoto(true);
                  }}
                  icon={mdiImageEdit}
                >
                  Editar
                </Botao>
              </div>
            </div>
            <input
              ref={fotoInputRef}
              type="file"
              name="foto"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </GridItem>
          <GridItem md={8} sm={12}>
            <div style={{ position: 'absolute', height: 0 }}>
              <input
                type="text"
                name="username"
                style={{
                  height: 0,
                  width: 0,
                  border: 'none',
                  outline: 'none',
                  margin: 0,
                  padding: 0,
                }}
              />
              <input
                type="password"
                name="password"
                style={{
                  height: 0,
                  width: 0,
                  border: 'none',
                  outline: 'none',
                  margin: 0,
                  padding: 0,
                }}
              />
            </div>
            <Grid>
              <GridItem sm={12} md={6}>
                <CampoTexto
                  instanceId="conta"
                  disabled
                  label="Conta"
                  value={value.email}
                  onChange={v => setValue({ ...value, email: v })}
                  error={fieldErrors?.email}
                />
              </GridItem>
              <GridItem sm={12} md={6}>
                <CampoTexto
                  instanceId="nome"
                  label="Nome"
                  value={value.nome}
                  onChange={v => setValue({ ...value, nome: v })}
                  error={fieldErrors?.nome}
                />
              </GridItem>
              <GridItem sm={12} md={6}>
                <CampoSenha
                  instanceId="senha"
                  label="Senha"
                  value={value.senha}
                  onChange={v => setValue({ ...value, senha: v })}
                  error={fieldErrors?.senha}
                  placeholder={value.id ? '(inalterada)' : ''}
                />
              </GridItem>
              <GridItem sm={12} md={6}>
                <CampoSenha
                  instanceId="confirmar_senha"
                  label="Confirmar senha"
                  value={value.confirmar_senha}
                  onChange={v => setValue({ ...value, confirmar_senha: v })}
                  error={fieldErrors?.confirmar_senha}
                />
              </GridItem>
              <GridItem sm={12} md={6}>
                <CampoSeletor
                  instanceId="mostrar_notificacoes"
                  noSearch
                  label="Receber notificações"
                  value={value.mostrar_notificacoes}
                  onChange={v =>
                    setValue({ ...value, mostrar_notificacoes: v })
                  }
                  error={fieldErrors?.mostrar_notificacoes}
                  options={[
                    {
                      id: 'chatbot_atendimento',
                      label: 'Chatbot e atendimento',
                    },
                    { id: 'atendimento', label: 'Somente atendimento' },
                  ]}
                />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem sm={12}>
            <Botao variant="primary" type="submit" disabled={isSubmitting}>
              Salvar
            </Botao>
          </GridItem>
        </Grid>
      </form>
    </>
  );
};
