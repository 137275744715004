import Titulo from '../../components/tipografia/Titulo';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import {
  mdiCalendar,
  mdiHandFrontLeft,
  mdiMagnify,
  mdiPlus,
  mdiRobot,
} from '@mdi/js';
import Tabs from '../../components/layout/Tabs';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import Card from './Card';
import Grid, { GridItem } from '../../components/layout/Grid';
import React from 'react';
import { useDebounce } from '../chat/useDebounce';
import Skeleton from '../../components/layout/Skeleton';
import IconeCanal from '../../components/layout/IconeCanal';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import AlertBox from '../../components/layout/AlertBox';
import { Tutorial } from '@/components/Tutorial';

type PageProps = {};

const Page = ({ }: PageProps) => {
  const [search, setSearch] = React.useState<string>('');
  const [filter, setFilter] = React.useState<any>({
    inicializacao: ['eq', 'canal'],
  });
  const debouncedSearch = useDebounce(search, 500);

  const [showModalPlay, setShowModalPlay] = React.useState<number | null>(null);
  const [showModalPlaySched, setShowModalPlaySched] = React.useState<
    number | null
  >(null);

  const openModal = (did: number) => {
    setShowModalPlay(did);
  };

  const closeModal = () => {
    setShowModalPlay(null);
  };

  const onConfirm = async () => {
    const res = await axios.post(`/api/auth/jwt`, { did: showModalPlay });
    if (res.status !== 200) {
      console.error('Erro ao obter token');
      return;
    }
    const jwt = res.data.result;

    window.open(
      `/webchat/?did=${showModalPlay}&a=${jwt}&t=${encodeURIComponent('/fim')}`,
      'webchatWindow',
      'popup=1',
    );
    setShowModalPlay(null);
  };

  const openModalSched = (did: number) => {
    setShowModalPlaySched(did);
  };

  const closeModalSched = () => {
    setShowModalPlaySched(null);
  };

  const onConfirmSched = async () => {
    const res = await axios.post(`/api/dialogo/${showModalPlaySched}/start`);

    closeModalSched();

    if (res.status === 200) {
      toast('Disparo agendado iniciado com sucesso', {
        toastId: 'success',
        position: 'top-center',
        type: 'success',
        autoClose: 5000,
      });
    } else {
      toast('Erro ao iniciar disparo agendado', {
        toastId: 'error',
        position: 'top-center',
        type: 'error',
        autoClose: 5000,
      });
    }
  };

  const query = useQuery(
    ['dialogo:search:filter', debouncedSearch, filter],
    async () => {
      const params = new URLSearchParams();
      params.append('txt', debouncedSearch);
      if (filter) {
        params.append('fi', JSON.stringify(filter));
      }
      const { data } = await axios.get('/api/dialogo?' + params.toString());
      return data;
    },
  );

  const queryCanais = useQuery(['canal.box'], async () => {
    const { data } = await axios.get('/api/canal/box');
    return data;
  });

  const canaisRestantes = queryCanais.data?.result?.filter(
    (item: any) =>
      !query.data?.result?.find((dialogo: any) => dialogo.canal_id === item.id),
  );

  const delItem = useMutation(
    async (id: number) => {
      await axios.delete(`/api/dialogo/${id}`);
    },
    {
      onSuccess: () => {
        toast('Item deletado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        query.refetch();
      },
      onError: () => {
        toast('Erro ao deletar item', {
          toastId: 'error',
          position: 'top-center',
          type: 'error',
          autoClose: 5000,
        });
      },
    },
  );

  return (
    <LayoutUsuario>
      <Modal
        show={!!showModalPlay}
        onClose={closeModal}
        title="Iniciar conversa"
        onCancel={closeModal}
        onConfirm={onConfirm}
        confirmProps={{
          label: 'Continuar',
        }}
      >
        <p>Ao continuar, você poderá testar este fluxo em uma nova janela.</p>
        <AlertBox variant="warning">
          <p>O fluxo será executado na íntegra.</p>
          <p>
            <b>Chamadas de API</b> e <b>atendimentos</b> neste fluxo serão
            executadas, e poderão gerar resultados inesperados!
          </p>
        </AlertBox>
      </Modal>

      <Modal
        show={!!showModalPlaySched}
        onClose={closeModalSched}
        title="Iniciar fluxo agendado"
        onCancel={closeModalSched}
        onConfirm={onConfirmSched}
        confirmProps={{
          label: 'Continuar',
        }}
      >
        <p>Ao continuar, o fluxo será iniciado em segundo plano.</p>
        <AlertBox variant="warning">
          <p>O fluxo será executado na íntegra.</p>
          <p>
            <b>Chamadas de API</b> e <b>atendimentos</b> neste fluxo serão
            executadas, e poderão gerar resultados inesperados!
          </p>
        </AlertBox>
      </Modal>

      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <Grid>
          <GridItem sm={12} md={4}>
            <VBox style={{ flex: 1 }}>
              <Titulo>Fluxos de atendimento</Titulo>
              <Descricao>Gerencie seus fluxos de atendimento</Descricao>
            </VBox>
          </GridItem>
          <GridItem sm={12} md={4}>

            <div style={{ textAlign: 'center' }}>
              <Tutorial ytId="_xDXAknzryc" ytSi="qQhjG4GIZCSv7Pai" />
            </div>
          </GridItem>
          <GridItem sm={12} md={4}>

            <div className={styles.headerAcoes} style={{ flex: 1 }}>
              <Botao variant="primary" icon={mdiPlus} href="/dialogo/novo">
                Novo
              </Botao>
            </div>
          </GridItem>
        </Grid>

        {/* Filtros */}
        <HBox>
          <CampoTexto
            prepend={
              <Icon
                path={mdiMagnify}
                size={1}
                color="var(--tc-color-gray-500)"
              />
            }
            placeholder="Pesquisar"
            value={search}
            onChange={setSearch}
          />
        </HBox>

        <VBox gap="12px">
          <Tabs
            gap={8}
            size="lg"
            tabs={[
              { id: 'canal', label: 'Respostas automáticas', icon: mdiRobot },
              {
                id: 'agendado',
                label: 'Disparos agendados',
                icon: mdiCalendar,
              },
              {
                id: 'manual',
                label: 'Disparos manuais',
                icon: mdiHandFrontLeft,
              },
            ]}
            selectedTab={filter.inicializacao[1]}
            onTabChange={tab =>
              setFilter({ ...filter, inicializacao: ['eq', tab] })
            }
          />

          <Grid>
            {query.isLoading && (
              <>
                <GridItem lg={3} md={4} sm={12}>
                  <Skeleton format="card" />
                </GridItem>
                <GridItem lg={3} md={4} sm={12}>
                  <Skeleton format="card" />
                </GridItem>
                <GridItem lg={3} md={4} sm={12}>
                  <Skeleton format="card" />
                </GridItem>
              </>
            )}
            {!query.isLoading && !query.data?.result?.length && (
              <GridItem lg={12}>
                <p style={{ textAlign: 'center', padding: '16px 0' }}>
                  Nenhum item encontrado
                </p>
              </GridItem>
            )}
            {!query.isLoading &&
              query.data?.result?.map((item: any) => (
                <GridItem key={item.id} lg={3} md={4} sm={12}>
                  <Card
                    onPlay={
                      ['canal', 'manual'].includes(item.inicializacao) &&
                        queryCanais.data?.result?.some(
                          (it: any) => it.tipo === 'webchat',
                        )
                        ? () => openModal(item.id)
                        : ['agendado'].includes(item.inicializacao)
                          ? () => openModalSched(item.id)
                          : undefined
                    }
                    item={item}
                    key={item.id}
                    onRemove={() => delItem.mutate(item.id)}
                  />
                </GridItem>
              ))}
            {!query.isLoading &&
              filter?.inicializacao?.[1] === 'canal' &&
              canaisRestantes?.length > 0 &&
              canaisRestantes.map((canal: any) => (
                <GridItem key={canal.id} lg={3} md={4} sm={12}>
                  <a
                    className={styles.cardCanalSemDialogo}
                    href={`/dialogo/novo?canal_id=${canal.id}`}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <HBox style={{ justifyContent: 'center' }}>
                        <IconeCanal
                          tipo={canal.tipo}
                          style={{ height: '14px' }}
                        />{' '}
                        {canal.nome}
                      </HBox>
                      <Icon path={mdiPlus} size={2} color="gray" />
                      <div className="text-muted fst-italic">
                        Crie um fluxo de atendimento para responder seus
                        clientes neste canal
                      </div>
                    </div>
                  </a>
                </GridItem>
              ))}
          </Grid>
        </VBox>
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
