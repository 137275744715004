import React from 'react';
import styles from './Mensagem.module.css';
import Badge from '../../components/layout/Badge';
import { ChatDtoEstado } from '@tera/shared/src/dto/ChatDto';
import Icon from '@mdi/react';
import {
  mdiAccount,
  mdiAlertCircle,
  mdiCheck,
  mdiCheckAll,
  mdiCheckCircle,
  mdiHeadset,
  mdiRobot,
  mdiSchool,
  mdiTag,
  mdiThumbDown,
  mdiThumbUp,
} from '@mdi/js';
import {
  ChatMensagemDto,
  ChatMensagemDtoEstado,
} from '@tera/shared/src/dto/ChatMensagemDto';
import TextoData from '../../components/tipografia/TextoData';
import { Conteudo } from './conteudo/Conteudo';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import Marcador from '@/components/layout/Marcador';
import VBox from '@/components/layout/VBox';

type IconDescriptor = {
  icon: string;
  label: string;
};

const iconDescriptor: Record<ChatMensagemDtoEstado, IconDescriptor> = {
  processada: { icon: mdiCheckAll, label: 'Mensagem entregue' },
  lida: { icon: mdiCheckAll, label: 'Mensagem lida' },
  erro: { icon: mdiAlertCircle, label: 'Erro ao enviar mensagem' },
};

const badgeByStatus: Record<
  ChatDtoEstado,
  (responsavel: string, solucionado?: boolean) => JSX.Element
> = {
  chatbot: r => (
    <Badge variant="info">
      {' '}
      <Icon path={mdiRobot} size="12px" /> Chatbot
    </Badge>
  ),
  atendimento: r => (
    <Badge variant="warning">
      <Icon path={mdiHeadset} size="12px" /> {r ?? `Em atendimento`}
    </Badge>
  ),
  finalizado: (r, solucionado) => (
    <Badge variant="primary">
      <Icon path={mdiCheckCircle} size="12px" /> Finalizado{' '}
      {r ? `por ${r}` : null}
      {solucionado === true && (
        <Icon path={mdiThumbUp} size="12px" color="var(--tc-color-success)" />
      )}
      {solucionado === false && (
        <Icon
          path={mdiThumbDown}
          size="12px"
          color="var(--tc-color-gray-500)"
        />
      )}
    </Badge>
  ),
  aguardando_cliente: r => (
    <Badge variant="warning">
      <Icon path={mdiAccount} size="12px" /> (Aguardando cliente)
    </Badge>
  ),
  aguardando_atendente: r => (
    <Badge variant="warning">
      <Icon path={mdiHeadset} size="12px" /> (Aguardando atendente)
    </Badge>
  ),
};

type BadgeStatusProps = {
  status: ChatDtoEstado;
  responsavel: string;
  solucionado?: boolean;
};

const BadgeStatus = ({
  status,
  responsavel,
  solucionado,
}: BadgeStatusProps): JSX.Element | null => {
  const bFunc = badgeByStatus[status];
  if (bFunc) return bFunc(responsavel, solucionado);
  return null;
};

const AutorMensagem = ({
  mensagens,
  index,
  origem,
  autor,
}: {
  mensagens: ChatMensagemDto[];
  index: number;
  origem: string;
  autor: string;
}) => {
  if (index > mensagens.length - 1) return null;

  if (origem === 'sistema')
    return <div className={styles.mensagemSistema}>Sistema</div>;

  if (
    origem === 'bot' &&
    (index === mensagens.length - 1 || mensagens[index + 1].origem !== 'bot')
  ) {
    return <div className={styles.mensagemNomeBot}>{autor}</div>;
  }

  if (
    origem === 'interna' &&
    (index === mensagens.length - 1 || mensagens[index + 1].origem !== 'bot')
  ) {
    return (
      <div className={styles.mensagemInterna}>
        Mensagem interna (só para atendentes)
      </div>
    );
  }
  if (
    origem === 'usuario' &&
    (index === mensagens.length - 1 ||
      mensagens[index + 1].origem !== 'usuario')
  )
    return <div className={styles.mensagemNomeUsuario}>{autor}</div>;

  if (origem === 'atendente') {
    const rAnt = mensagens[index + 1]?.metadados?.remetente?.nome
    const remetente = mensagens[index]?.metadados?.remetente?.nome;

    if (
      index === mensagens.length - 1 ||
      mensagens[index + 1].origem !== 'atendente' ||
      rAnt !== remetente
    )
      return (
        <div className={styles.mensagemNomeAtendente}>
          {remetente ?? 'Atendente'}
        </div>
      );
  }

  return null;
};

type MensagemProps = {
  mensagens: ChatMensagemDto[];
  item: ChatMensagemDto;
  index: number;
  autor?: string;
  onOptionChosen?: (opcao: string) => void;
  onSelectedKbSection: (section: any) => void;
};

const Mensagem = ({
  item,
  index,
  mensagens: value,
  autor = 'Cliente',
  onOptionChosen,
  onSelectedKbSection,
}: MensagemProps) => {
  const data = item.data_criacao
    ? new Date(item.data_criacao).setHours(0, 0, 0, 0)
    : '';
  const dataAnt =
    index < value.length - 1 && value[index + 1].data_criacao
      ? new Date(value[index + 1].data_criacao!).setHours(0, 0, 0, 0)
      : '';

  if (item.origem === 'sistema') {
    return (
      <div className={styles.mensagemContainer} key={`mensagem-${index}`}>
        <div className={styles.mensagemSistema}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '2px',
              width: '100%',
              lineHeight: '24px',
              margin: '0 12px',
            }}
          >
            {item.conteudo_ou_url === 'CHAT_MARCADOR' &&
              item.metadados?.marcador === 'TRANSFERIDO' && (
                <>
                  <span></span>
                  <Badge variant="info">
                    Transferido para{' '}
                    {item.metadados.usuario_responsavel_nome ??
                      item.metadados.grupo_responsavel_nome}
                  </Badge>
                  <span>
                    <span>
                      <TextoData value={item.data_criacao} />
                    </span>
                  </span>
                </>
              )}
            {item.conteudo_ou_url === 'CHAT_MARCADOR' &&
              item.metadados?.marcador !== 'TRANSFERIDO' && (
                <>
                  <span></span>
                  {item.metadados?.marcador_id > 0 ? (
                    <Marcador color={item.metadados?.marcador_cor}>
                      Marcado como {item.metadados?.marcador}
                      {item.metadados?.usuario_nome != null && (
                        <span> por {item.metadados?.usuario_nome}</span>
                      )}
                    </Marcador>
                  ) : (
                    <Badge variant="info">
                      <Icon path={mdiTag} size="12px" /> Marcado como{' '}
                      {item.metadados?.marcador}
                    </Badge>
                  )}
                  <span>
                    <span>
                      <TextoData value={item.data_criacao} />
                    </span>
                  </span>
                </>
              )}
            {item.conteudo_ou_url === 'CHAT_STATUS' && (
              <>
                <span></span>
                <BadgeStatus
                  status={item.metadados?.status as ChatDtoEstado}
                  responsavel={
                    item.metadados?.usuario_responsavel_nome ??
                    item.metadados?.grupo_responsavel_nome
                  }
                  solucionado={item.metadados?.solucionado}
                />
                <span>
                  <span>
                    <TextoData value={item.data_criacao} />
                  </span>
                </span>
              </>
            )}

            {item.conteudo_ou_url === 'CHAT_SOLUCIONADO' && (
              <>
                <span></span>
                <Badge variant='info'>
                  {item.metadados?.solucionado === true && <Icon path={mdiThumbUp} size='12px' color='var(--tc-color-success)' />}
                  {item.metadados?.solucionado === false && <Icon path={mdiThumbDown} size='12px' color='var(--tc-color-gray-500)' />}

                  Marcado como {item.metadados?.solucionado ? 'solucionado' : 'não resolvido'} por {item.metadados?.usuario_finalizador_nome}
                </Badge>
                <span>
                  <span>
                    <TextoData value={item.data_criacao} />
                  </span>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={[
          styles.mensagemContainer,
          styles[`mensagemContainer-${item.origem}`],
        ].join(' ')}
        key={`mensagem-${index}`}
      >
        <div className={styles.caixaMensagem}>
          <AutorMensagem
            mensagens={value}
            index={index}
            origem={item.origem}
            autor={autor}
          />
          <div
            className={[
              styles.mensagem,
              styles[`mensagem-${item.origem}`],
            ].join(' ')}
          >
            <div className={styles.mensagemConteudo}>
              <Conteudo mime={item.tipo_mime} value={item} />
              {(item.metadados?.opcoes ?? item.opcoes)?.map(
                (opcao: any, idx: number) => (
                  <Botao
                    disabled={!onOptionChosen}
                    variant={onOptionChosen ? 'primary' : 'outline-secondary'}
                    onClick={() => onOptionChosen?.(opcao.content)}
                    key={idx}
                    style={{ width: '100%', margin: '4px 0' }}
                  >
                    <div style={{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{opcao?.content ? opcao.content?.toString() : ""}</div>
                  </Botao>
                ),
              )}
            </div>
            <div className={styles.mensagemData}>
              <HBox>
                <div
                  style={{
                    lineHeight: '16px',
                    color: 'var(--tc-color-gray-500)',
                  }}
                >
                  <TextoData value={item.data_criacao} />
                </div>
                <div
                  className={styles[`mensagem-status-${item.origem}`]}
                  style={{ lineHeight: '16px', margin: 'auto' }}
                >
                  {iconDescriptor[item.estado_atual!] && (
                    <Icon
                      path={iconDescriptor[item.estado_atual!].icon}
                      size="16px"
                      title={iconDescriptor[item.estado_atual!].label}
                    />
                  )}
                  {!iconDescriptor[item.estado_atual!] && (
                    <Icon
                      path={mdiCheck}
                      size="16px"
                      color="var(--tc-color-gray-400)"
                      title="Enviando mensagem..."
                    />
                  )}
                </div>
              </HBox>
            </div>
          </div>
        </div>
        {item.origem === 'usuario' && item.metadados?.kb && (
          <HBox
            style={{
              maxWidth: '50%',
              justifyContent: 'flex-end',
              border: '1px solid var(--tc-color-gray-300)',
              padding: '4px',
              borderRadius: '32px',
            }}
          >
            <div
              style={{
                borderRadius: '40px',
                minHeight: '80px',
                minWidth: '80px',
                backgroundColor: 'var(--tc-color-gray-200)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon
                path={mdiSchool}
                size="64px"
                color="var(--tc-color-gray-400)"
              />
            </div>
            <VBox style={{ lineHeight: '18px', padding: '4px' }}>
              <div
                style={{
                  textAlign: 'center',
                  color: 'var(--tc-color-gray-700)',
                }}
              >
                Documentos relevantes encontrados!
              </div>
              {item.metadados?.kb?.sessoes?.[0] && (
                <Botao
                  variant="outline-primary"
                  onClick={() =>
                    onSelectedKbSection(item.metadados!.kb.sessoes[0])
                  }
                >
                  1: {item.metadados.kb.sessoes[0].secao_titulo}
                </Botao>
              )}
              {item.metadados?.kb?.sessoes?.[1] && (
                <Botao
                  variant="outline-primary"
                  onClick={() =>
                    onSelectedKbSection(item.metadados!.kb.sessoes[1])
                  }
                >
                  2: {item.metadados.kb.sessoes[1].secao_titulo}
                </Botao>
              )}
            </VBox>
          </HBox>
        )}
      </div>

      {data !== dataAnt && (
        <div className={styles.marcadorData}>
          <span>{new Date(data).toLocaleDateString()}</span>
        </div>
      )}
    </>
  );
};
export default Mensagem;
