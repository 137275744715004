import CampoData from '@/components/Formulario/CampoData';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import CampoMultiSeletor from '@/components/Formulario/CampoMultiSeletor';
import { useQuery } from 'react-query';
import axios from 'axios';
import CampoNumero from '@/components/Formulario/CampoNumero';
import HBox from '@/components/layout/HBox';
import CampoMemo from '@/components/Formulario/CampoMemo';
import CampoSeletor from '@/components/Formulario/CampoSeletor';

export const FormAtividade = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const qClientes = useQuery(
    'cliente/box',
    async () => {
      const res = await axios.get('/api/cliente/box');
      return res.data;
    },
  );
  const clientesOptions =
    qClientes.data?.result?.map((it: any) => ({
      id: it.id,
      label: it.nome,
    })) ?? [];



  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <GridItem sm={12} md={6} lg={4}>
        <CampoTexto
          instanceId="titulo"
          value={value?.titulo ?? ''}
          onChange={v => onChange({ ...value, titulo: v })}
          label="Título"
          error={fieldErrors?.titulo}
        />
      </GridItem>
      <GridItem sm={12} md={6} lg={4}>
        <CampoData
          instanceId="data_evento"
          type='datetime-local'
          value={value?.data_evento ?? ''}
          onChange={v => onChange({ ...value, data_evento: v })}
          label="Data"
          error={fieldErrors?.data_evento}
        />
      </GridItem>
      <GridItem sm={12} lg={4}>
        <CampoNumero
          instanceId="avisar_minutos"
          label='Avisar'
          value={value?.avisar_minutos ?? 0}
          onChange={v => onChange({ ...value, avisar_minutos: v })}
          error={fieldErrors?.avisar_minutos}
          append={<span style={{ whiteSpace: 'nowrap' }}>minutos antes</span>}
        />
      </GridItem>

      <GridItem sm={12}>
        <CampoSeletor
          instanceId='estado'
          label="Estado da atividade"
          value={value?.estado ?? ''}
          onChange={v => onChange({ ...value, estado: v })}
          error={fieldErrors?.estado}
          options={[
            { id: 'pendente', label: 'Pendente' },
            { id: 'concluida', label: 'Concluída' },
            { id: 'cancelada', label: 'Cancelada' },
          ]}
        />
      </GridItem>

      <GridItem sm={12}>
        <CampoSeletor
          nullable
          instanceId='id_cliente'
          value={value?.id_cliente ?? []}
          onChange={v => onChange({ ...value, id_cliente: v })}
          label="Cliente"
          error={fieldErrors?.id_cliente}
          options={clientesOptions}
        />
      </GridItem>

      <GridItem sm={12}>
        <CampoTexto
          instanceId='local'
          label='Local'
          value={value?.local ?? ''}
          onChange={v => onChange({ ...value, local: v })}
          error={fieldErrors?.local}
        />
      </GridItem>
      <GridItem sm={12}>
        <CampoMemo
          instanceId='obs'
          label='Observações'
          value={value?.obs ?? ''}
          onChange={v => onChange({ ...value, obs: v })}
          error={fieldErrors?.obs}
        />
      </GridItem>
    </Grid>
  );
};
