import React, { useEffect } from 'react';
import styles from './CampoTexto.module.css';
import Botao from '../Botao';
import Icon from '@mdi/react';
import {
  mdiCheck,
  mdiClose,
  mdiEye,
  mdiEyeOff,
  mdiInformationSlabCircleOutline,
} from '@mdi/js';
import HBox from '../layout/HBox';
import { Tooltip } from '../layout/Tooltip';

type CampoTextoProps = {
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  error?: string;
  showStrength?: boolean;
  instanceId?: string;
  [key: string]: any;
};

export default function CampoSenha({
  value,
  onChange,
  label,
  placeholder,
  required,
  prepend,
  append,
  error,
  showStrength,
  instanceId,
  ...props
}: Readonly<CampoTextoProps>) {
  const [type, setType] = React.useState('password');

  const [strength, setStrength] = React.useState(0);

  const hasUpper = value ? /[A-Z]/.test(value) : false;
  const hasLower = value ? /[a-z]/.test(value) : false;
  const hasNumber = value ? /\d/.test(value) : false;
  const hasSpecial = value ? /[^A-Za-z0-9]/.test(value) : false;
  const length = value?.length ?? 0;
  const hasNumericSequence = value
    ? /(?=\d{3,})0?1?2?3?4?5?6?7?8?9?/.test(value)
    : false;

  useEffect(() => {
    if (!showStrength) return;

    if (!value?.length) {
      setStrength(0);
      return;
    }

    let s = length / 8;
    if (hasUpper) s += 0.4;
    if (hasLower) s += 0.4;

    if (hasNumber) s += 0.4;
    if (hasSpecial) s += 0.6;
    if (hasNumericSequence) s -= 0.6;

    setStrength(Math.min(3, Math.max(0, Math.floor(s))));
  }, [value]);

  const scolor = [
    'var(--tc-color-gray-400)',
    'var(--tc-color-danger)',
    'var(--tc-color-warning)',
    'var(--tc-color-success)',
  ];

  return (
    <div style={{ width: '100%' }}>
      <label>{label}</label>
      <div
        className={[styles.container, error ? styles.error : null].join(' ')}
      >
        {prepend && <div className={styles.prepend}>{prepend}</div>}
        <input
          {...props}
          id={instanceId}
          type={type}
          value={value}
          onChange={e => onChange?.(e.target.value)}
          placeholder={placeholder}
          required={required}
        />
        <div className={styles.append}>
          {append}
          <Botao
            variant="none-secondary"
            onClick={() => setType(type === 'password' ? 'text' : 'password')}
          >
            <Icon
              path={type === 'password' ? mdiEyeOff : mdiEye}
              size="18px"
              style={{ verticalAlign: 'middle' }}
              color="var(--tc-color-gray-500)"
            />
          </Botao>
        </div>
      </div>
      {showStrength && (
        <HBox
          style={{
            margin: '4px 0',
            height: '8px',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '48px',
              height: '8px',
              backgroundColor: scolor[strength],
            }}
          ></div>
          <div
            style={{
              width: '48px',
              height: '8px',
              backgroundColor: strength > 1 ? scolor[strength] : scolor[0],
            }}
          ></div>
          <div
            style={{
              width: '48px',
              height: '8px',
              backgroundColor: strength > 2 ? scolor[strength] : scolor[0],
            }}
          ></div>
          <Tooltip
            position="left"
            text={
              <div>
                <div>
                  Força da senha:{' '}
                  <span>
                    {strength < 2 ? 'Baixa' : strength < 3 ? 'Média' : 'Alta'}
                  </span>
                </div>
                <div>
                  <BoolIcon value={length >= 8} /> Tamanho maior que 7
                  caracteres
                </div>
                <div>
                  <BoolIcon value={hasUpper} /> Pelo menos um caractere
                  maiúsculo
                </div>
                <div>
                  <BoolIcon value={hasLower} /> Pelo menos um caractere
                  minúsculo
                </div>
                <div>
                  <BoolIcon value={hasNumber} /> Pelo menos um caractere
                  numérico
                </div>
                <div>
                  <BoolIcon value={hasSpecial} /> Pelo menos um caractere
                  especial
                </div>
                <div>
                  <BoolIcon value={!hasNumericSequence} /> Não tem sequência
                  (123...)
                </div>
              </div>
            }
          >
            <Icon
              path={mdiInformationSlabCircleOutline}
              color="var(--tc-color-info)"
              size="16px"
            />
          </Tooltip>
        </HBox>
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}

const BoolIcon = ({ value }: { value: boolean }) => {
  return (
    <Icon
      path={value ? mdiCheck : mdiClose}
      size="16px"
      color={value ? 'var(--tc-color-success)' : 'var(--tc-color-danger)'}
    />
  );
};
