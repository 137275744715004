import Icon from '@mdi/react';
import CampoTexto from '../../components/Formulario/CampoTexto';
import AlertBox from '../../components/layout/AlertBox';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import TituloChat from './TituloChat';
import styles from './page.module.css';
import {
  mdiCheckCircle,
  mdiCross,
  mdiHeadset,
  mdiInformationOutline,
  mdiMagnify,
  mdiMessageProcessing,
  mdiOpenInNew,
  mdiRobot,
  mdiTrashCan,
} from '@mdi/js';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import Tabs from '../../components/layout/Tabs';
import Conversas from './Conversas';
import AlertasAtendimento from './AlertasAtendimento';
import Mensagens from './Mensagens';
import ChatDto from '@tera/shared/src/dto/ChatDto';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useWebsocket from './useWebsocket';
import {
  ChatMessagePayload,
  ChatMessagePayloadFile,
} from '@tera/shared/src/types/ChatMessagePayload';
import { useDebounce } from './useDebounce';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import IconeCanal from '../../components/layout/IconeCanal';
import HBox from '../../components/layout/HBox';
import Modal from '../../components/layout/Modal';
import { useAuth } from '../../lib/useAuth';
import Subtitulo from '@/components/tipografia/Subtitulo';
import Titulo from '@/components/tipografia/Titulo';
import VBox from '@/components/layout/VBox';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Botao from '@/components/Botao';
import { Tutorial } from '@/components/Tutorial';
import { FormMensagemProgramada } from '../mensagem_programada/FormMensagemProgramada';
import CampoMemo from '@/components/Formulario/CampoMemo';
import { toast } from 'react-toastify';
import { queryClient } from '@/queryClient';

export const stateEnum = {
  INIT: 'init',
  LOADING_PREFS: 'loading_prefs',
  PREFS_LOADED: 'prefs_loaded',
  WAIT_DEBOUNCE: 'wait_debounce',
  ERROR: 'error',
  READY: 'ready',
} as const;
export type StateEnumType = typeof stateEnum[keyof typeof stateEnum];

type ModalAbrirChatMensagemProgramadaProps = {
  canalId: number | null;
  open: boolean;
  onClose: () => void;
  onConfirm: (mensagemId: string) => void;
};

const ModalAbrirChatMensagemProgramada = ({
  canalId,
  open,
  onClose,
  onConfirm,
}: ModalAbrirChatMensagemProgramadaProps) => {
  const [idMensagem, setIdMensagem] = useState<string | null>(null);
  const [novaMsgProgramada, setNovaMsgProgramada] = useState<string>('');
  const [nmpFieldErrors, setNmpFieldErrors] = useState<Record<string, string>>({});
  const [msgCriada, setMsgCriada] = useState<boolean>(false);

  const mensagensQuery = useQuery(
    ['mensagem_programada', canalId],
    () => {
      return fetch(
        '/api/mensagem_programada?fi=' +
        encodeURIComponent(JSON.stringify({ canal_id: ['eq', canalId] })),
      ).then(res => res.json());
    },
    {
      staleTime: 30000,
      onSuccess: () => {
        console.log('mensagensQuery returned');
      },
    },
  );

  const mensagens =
    mensagensQuery.data?.result?.map((msg: any) => ({
      id: msg.id,
      label: msg.nome,
    })) ?? [];

  const mensagem = mensagensQuery.data?.result?.find(
    (msg: any) => msg.id === idMensagem,
  );

  const createNovaMsgProgramada = useMutation({
    mutationFn: async (data: any) => {
      return axios.post('/api/mensagem_programada', data);
    },
    onSuccess: ({ data }) => {
      toast('Modelo adicionado com sucesso', {
        toastId: 'add-success',
        position: 'top-center',
        type: 'success',
        autoClose: 5000,
      });

      setNovaMsgProgramada('');
      queryClient.invalidateQueries(['mensagem_programada']);
      setMsgCriada(data.result.estado !== 'aprovada');
      if (data.result.estado === 'aprovada') {
        setIdMensagem(data.result.id);
      }
    },
    onError: (err: any) => {
      if (err.response?.status === 422) {
        setNmpFieldErrors(err.response?.data?.fieldErrors ?? {});
      }
      toast(err.response?.data?.msg ?? 'Erro desconhecido', {
        toastId: 'add-error',
        position: 'top-center',
        type: 'warning',
        autoClose: 5000,
      });
    },
  })

  const handleCreateNovaMsgProgramada = async () => {
    createNovaMsgProgramada.mutateAsync({
      nome: 'Retomar atendimento',
      conteudo: novaMsgProgramada,
      canal_id: canalId,
      categoria: 'notificacao',
    });
  }

  const handleClose = async () => {
    onClose();
    setIdMensagem(null);
    setNovaMsgProgramada('');
    setNmpFieldErrors({});
    setMsgCriada(false);
  }

  const handleConfirm = async () => {
    onConfirm(idMensagem!);
    setIdMensagem(null);
    setNovaMsgProgramada('');
    setNmpFieldErrors({});
    setMsgCriada(false);
  }

  return (
    <Modal
      title="Abrir chat"
      show={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmProps={{
        label: 'Enviar',
        disabled: !idMensagem,
      }}
      cancelProps={{
        label: 'Cancelar',
      }}
    >
      {mensagensQuery.isFetching && <VBox center><p>Carregando...</p></VBox>}
      {!mensagensQuery.isFetching && !msgCriada && mensagens?.length === 0 && (
        <VBox center>
          <Subtitulo style={{ color: 'var(--tc-color-primary)' }}>Seu primeiro modelo de mensagem, pronto para uso!</Subtitulo>
          <p>Escreva uma mensagem clara e objetiva para iniciar a conversa com o cliente. Lembre de explicar o por que está entrando em contato com ele.</p>
          <CampoMemo
            instanceId='conteudo'
            placeholder='Escreva aqui a mensagem que deseja enviar ao cliente'
            value={novaMsgProgramada}
            onChange={setNovaMsgProgramada}
            error={nmpFieldErrors['conteudo']}
            min={25}
            max={250}
          />
          <p style={{ fontStyle: 'italic' }}>(Um exemplo: "Olá! Nosso time comercial está disponível neste momento para conversar com você. Podemos continuar?")</p>
          <Botao variant="primary" onClick={handleCreateNovaMsgProgramada} disabled={createNovaMsgProgramada.isLoading}>
            Confirmar
          </Botao>
        </VBox>
      )}

      {!mensagensQuery.isFetching && !msgCriada && mensagens?.length > 0 && (
        <>
          <CampoSeletor
            label="Selecione a mensagem programada a enviar para o cliente"
            options={mensagens}
            value={idMensagem ?? undefined}
            onChange={setIdMensagem}
            optionLabel="nome"
            optionValue="id"
          />
          <div
            style={{
              marginTop: '1rem',
              fontSize: '1.2rem',
              fontWeight: 'lighter',
              textAlign: 'center',
            }}
          >
            {mensagem?.conteudo ?? '(nenhuma mensagem selecionada)'}
          </div>

          <AlertBox variant="info" style={{ marginTop: '1rem' }}>
            <Icon path={mdiInformationOutline} size={1} />
            <b>Nota:</b> Outras conversas em aberto com o cliente serão encerradas
          </AlertBox>

        </>
      )}

      {!mensagensQuery.isFetching && msgCriada && (
        <VBox center>
          <Subtitulo style={{ color: 'var(--tc-color-primary)' }}>Em análise</Subtitulo>
          <img src="/look-msg-model-min.png" style={{ width: '300px' }} />
          <p>O novo modelo de mensagem está em análise. Assim que for aprovado, estará disponível para uso.</p>
        </VBox>
      )}

    </Modal>
  );
};

const pubKey = import.meta.env.VITE_PUB_KEY;

const Page = () => {
  const [urlSearch] = useSearchParams();
  const navigate = useNavigate();

  const [userInteracted, setUserInteracted] = React.useState<boolean>(false);
  const [selectedChatIndex, setSelectedChatIndex] = React.useState<number>(-1);
  const [chatEstadoIsLoading, setChatEstadoIsLoading] =
    React.useState<boolean>(false);

  const [confirmCloseChat, setConfirmCloseChat] =
    React.useState<boolean>(false);
  const [confirmOpenChat, setConfirmOpenChat] = React.useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] =
    React.useState<NotificationPermission>('default');

  const uid = urlSearch.get('uid');

  const state = useRef<StateEnumType>(stateEnum.INIT);

  const [query, setQuery] = React.useState<{
    search?: string;
    chatStatus?: string;
    canalId?: number | null;
    userId?: number;
  }>({
    search: undefined,
    chatStatus: undefined,
    canalId: null,
    userId: uid != null ? +uid : undefined,
  });
  const debouncedQuery = useDebounce(query, 650);

  const { user } = useAuth();

  const {
    socket: websocket,
    wsState,
    chatState,
    chatAddTag,
    reloadChats,
    selectChat,
    loadMoreChats,
    sendMessage,
    atenderFila,
    sendNotification,
    sendAudio,
    loadMore,
  } = useWebsocket({
    userId: user?.id,
    userRole: user?.papel,
    query: debouncedQuery,
    onLoadingChats: (status: boolean) => {
      console.log('reload chats', status);
    },
    onChatsUpdated: (chats: ChatDto[]) => {
      console.log('chats updated', chats);
    },
    onConnected: () => {
      console.log('reconnected');
      console.warn('reload by reconnection');
      setTimeout(() => reloadChats(debouncedQuery), 100);
    },
  });

  const deltaThreshold = 50
  const countThreshold = 5

  const isPageActive = useRef(true);
  const pageActivityCounter = useRef(0);
  const lastFrameTime = useRef(Date.now());

  const activityIntervalRef = useRef<any>(null);


  const monitorFrameActivity = () => {
    lastFrameTime.current = Date.now();
    requestAnimationFrame(monitorFrameActivity);
  }

  useEffect(() => {
    // Inicia o monitoramento
    requestAnimationFrame(monitorFrameActivity);

    activityIntervalRef.current = setInterval(() => {
      const currentFrameTime = Date.now();

      const delta = currentFrameTime - lastFrameTime.current;

      // Detectar retorno à atividade
      if (delta <= deltaThreshold) {
        pageActivityCounter.current = Math.min(countThreshold, pageActivityCounter.current + 1);
      } else {
        pageActivityCounter.current = Math.max(-countThreshold, pageActivityCounter.current - 1);
      }

      if (pageActivityCounter.current > (countThreshold * 0.75) && !isPageActive.current) {
        isPageActive.current = true;
        websocket.emit('active', true);
        console.log('active');
      } else if (pageActivityCounter.current < -(countThreshold * 0.75) && isPageActive.current) {
        isPageActive.current = false;
        websocket.emit('active', false);
        console.log('inactive');
      }
    }, 50)

    // Cleanup ao desmontar
    return () => {
      clearInterval(activityIntervalRef.current);
    };
  }, []);

  const selectedChat = chatState.chats?.find(
    (chat: any) => chat.canal_cliente_id?.toString() === chatState.selectedChatClientId,
  );
  const chatCanBeOpened = selectedChat
    ? selectedChat.estado !== 'atendimento' &&
    new Date().getTime() -
    new Date(selectedChat.ultmsg_data_criacao).getTime() <
    23 * 50 * 60 * 1000
    : false;

  const loadMoreMessages = useCallback(
    (dt: string) => {
      console.log('is fetching? ', chatState.loadingMessages);
      if (chatState.loadingMessages) return;

      console.log('fetching more');

      loadMore(dt);
    },
    [chatState.loadingMessages, loadMore],
  );

  const handleSelectedChatIndexChange = useCallback(
    (index: number) => {
      console.log('selected chat index', index, 'chat', chatState.chats[index]);
      selectChat(chatState.chats[index]);
      setSelectedChatIndex(index);
    },
    [chatState.chats, selectChat],
  );

  const handleChatEstadoChange = useCallback(
    (estado: string, solucionado?: boolean) => {
      setChatEstadoIsLoading(() => true);
      try {
        websocket.emit('chat.state', {
          chat_id: chatState.chats[selectedChatIndex].id,
          state: estado,
          solucionado,
        });
      } finally {
        setChatEstadoIsLoading(() => false);
      }
    },
    [selectedChatIndex, websocket, chatState.chats],
  );

  const onChatEstadoChange = useCallback(
    async (estado: string, solucionado?: boolean): Promise<void> => {
      // if (estado === 'finalizado') {
      //   setConfirmCloseChat(true);
      //   return;
      // }

      if (estado === 'atendimento' && !chatCanBeOpened) {
        setConfirmOpenChat(true);
        return;
      }

      handleChatEstadoChange(estado, solucionado);
    },
    [handleChatEstadoChange, chatCanBeOpened],
  );

  const handleSend = useCallback(
    async (message: string, attachments: File[]) => {
      const _attachments = await Promise.all(
        attachments.map(
          async f =>
            ({
              name: f.name,
              size: f.size,
              type: f.type,
              buffer: await f.arrayBuffer(),
            }) as unknown as ChatMessagePayloadFile,
        ),
      );

      const msg: ChatMessagePayload = {
        canal_cliente_id: chatState.chats[selectedChatIndex].canal_cliente_id,
        canal_id: chatState.chats[selectedChatIndex].canal_id,
        message,
        attachments: _attachments,
        __trace: 5,
      };

      sendMessage(msg);
    },
    [selectedChatIndex, chatState.chats, sendMessage],
  );

  const handleSendAudio = useCallback(
    async (audio: Blob) => {
      sendAudio(
        chatState.chats[selectedChatIndex].canal_cliente_id,
        chatState.chats[selectedChatIndex].canal_id,
        audio,
      );
    },
    [chatState.chats, selectedChatIndex, sendAudio],
  );

  const queryCanais = useQuery(['canal.box'], async () => {
    const { data } = await axios.get('/api/canal/box');
    return data;
  });

  const queryClientes = useQuery(['cliente', uid], async () => {
    if (!uid) return;

    const { data } = await axios.get('/api/cliente/' + uid);
    return data;
  });
  const nomeCliente = queryClientes.data?.result?.nome;

  const canais = [
    {
      id: '',
      label: <span style={{ paddingLeft: '22px' }}>Todos os canais</span>,
    },
  ];
  if (queryCanais.isSuccess) {
    canais.push(
      ...queryCanais.data.result
        .map((c: any) => ({
          id: c.id,
          sort: c.nome,
          label: (
            <HBox gap="8px">
              <IconeCanal tipo={c.tipo} style={{ height: '14px' }} />
              {c.nome}
            </HBox>
          ),
        }))
        .sort((a: any, b: any) => a.sort.localeCompare(b.sort)),
    );
  }

  function subscribeUserToPush() {
    return navigator.serviceWorker
      .register('/service-worker.js')
      .then(function (reg) {
        let serviceWorker;
        if (reg.installing) {
          serviceWorker = reg.installing;
          // console.log('Service worker installing');
        } else if (reg.waiting) {
          serviceWorker = reg.waiting;
          // console.log('Service worker installed & waiting');
        } else if (reg.active) {
          serviceWorker = reg.active;
          // console.log('Service worker active');
        }

        if (serviceWorker) {
          console.log('sw current state', serviceWorker.state);
          if (serviceWorker.state === 'activated') {
            //If push subscription wasnt done yet have to do here
            console.log('sw already activated - Do watever needed here');

            const subscribeOptions = {
              userVisibleOnly: true,
              applicationServerKey: pubKey,
            };

            reg.pushManager
              .subscribe(subscribeOptions)
              .then(function (pushSubscription) {
                axios.post('/api/notificacao', { token: pushSubscription })
                  .then(res => {
                    console.log(res);
                  });
              });
          }
          serviceWorker.addEventListener('statechange', function (e: any) {
            console.log('sw statechange : ', e.target.state);
            if (e.target.state === 'activated') {
              // use pushManger for subscribing here.
              console.log(
                'Just now activated. now we can subscribe for push notification',
              );
              const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: pubKey,
              };

              reg.pushManager
                .subscribe(subscribeOptions)
                .then(function (pushSubscription) {
                  axios.post('/api/notificacao', { token: pushSubscription })
                    .then(res => {
                      console.log(res);
                    });
                });
            }
          });
        }
      });
  }

  function askPermission() {
    Notification.requestPermission(function (result) {
      setNotificationStatus(result);
      if (result !== 'granted') {
        console.warn("We weren't granted permission.");
      } else {
        subscribeUserToPush();
      }
    });
  }

  useEffect(() => {
    if (state.current === stateEnum.INIT) {
      state.current = stateEnum.LOADING_PREFS;
    }
    axios
      .get('/api/chat/user_prefs')
      .then(res => {
        const { data } = res;

        const newQuery = {
          ...query,
          search: data?.search,
          chatStatus: data?.chatStatus,
        };

        if (state.current === stateEnum.LOADING_PREFS) {
          state.current = stateEnum.PREFS_LOADED;
        }

        setQuery(newQuery);

        console.log('startup');
      })
      .finally(() => {
        console.log('connecting to socket');
        websocket.connect();
      });

    return () => {
      console.log('unloading component');
      websocket.disconnect();
    };
  }, [websocket]);

  useEffect(() => {
    if (state.current === stateEnum.PREFS_LOADED) {
      state.current = stateEnum.WAIT_DEBOUNCE;
    }
  }, [query]);

  useEffect(() => {
    if (userInteracted) return;

    const firstInteractionHandler = () => {
      setUserInteracted(true);
    };

    window.addEventListener('click', firstInteractionHandler);
    window.addEventListener('touchstart', firstInteractionHandler);

    return () => {
      window.removeEventListener('click', firstInteractionHandler);
      window.removeEventListener('touchstart', firstInteractionHandler);
    };
  }, [userInteracted]);

  useEffect(() => {
    if (
      chatState.chats?.[selectedChatIndex]?.canal_cliente_id?.toString() !==
      chatState.selectedChatClientId
    ) {
      const index = chatState.chats.findIndex(
        (c: any) => c.canal_cliente_id?.toString() === chatState.selectedChatClientId,
      );
      setSelectedChatIndex(index);
    }
  }, [
    chatState.chats,
    chatState.selectedChatClientId,
    selectedChatIndex,
    handleSelectedChatIndexChange,
  ]);

  useEffect(() => {
    setNotificationStatus(
      !('serviceWorker' in navigator) || !('PushManager' in window)
        ? 'denied'
        : Notification.permission,
    );
  }, []);

  useEffect(() => {
    if (state.current === stateEnum.WAIT_DEBOUNCE) {
      state.current = stateEnum.READY;
      return;
    }

    if (state.current !== stateEnum.READY) return;

    console.warn('saving preferences', debouncedQuery);

    axios.post('/api/chat/user_prefs', debouncedQuery).then(() => {
      console.warn('reload by preferences change');
      reloadChats(debouncedQuery);
    });
  }, [debouncedQuery]);

  const [selectedKbSection, setSelectedKbSection] = useState<any>(null);

  return (
    <LayoutUsuario>
      {selectedKbSection && (
        <Modal
          size="md"
          title="Base de conhecimento"
          show={!!selectedKbSection}
          onClose={() => setSelectedKbSection(null)}
          onCancel={() => setSelectedKbSection(null)}
          confirmProps={{
            label: (
              <span>
                Ver documentação completa{' '}
                <Icon path={mdiOpenInNew} size="16px" />
              </span>
            ),
            href: `/base_conhecimento/${selectedKbSection?.id}/view#secao-${selectedKbSection?.secao_id}`,
            linkTarget: '_blank',
          }}
        >
          <VBox>
            <Titulo>{selectedKbSection?.titulo}</Titulo>

            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
              {selectedKbSection?.secao_titulo}
            </p>

            <p>{selectedKbSection?.secao_conteudo}</p>

            <AlertBox variant="info">
              Nota: Ver a documentação completa abrirá uma nova aba. Você pode
              fechá-la depois para voltar a este chat.
            </AlertBox>
          </VBox>
        </Modal>
      )}

      <Modal
        title="Finalizar atendimento?"
        show={confirmCloseChat}
        onClose={() => setConfirmCloseChat(false)}
        onConfirm={() => {
          setConfirmCloseChat(false);
          handleChatEstadoChange('finalizado');
        }}
        confirmProps={{
          label: 'Finalizar',
        }}
        cancelProps={{
          label: 'Continuar atendimento',
        }}
      >
        <p>Tem certeza que deseja fechar este chat?</p>
      </Modal>

      <ModalAbrirChatMensagemProgramada
        canalId={selectedChat?.canal_id || selectedChat?.canal?.id}
        open={confirmOpenChat}
        onClose={() => setConfirmOpenChat(false)}
        onConfirm={(mensagemId: string) => {
          setConfirmOpenChat(false);
          sendNotification(
            selectedChat.id,
            selectedChat.canal_cliente_id?.toString(),
            +mensagemId,
          );
          // handleChatEstadoChange('aguardando_cliente')
        }}
      />

      <div className={styles.container}>
        <div
          className={[
            styles.conversasContainer,
            selectedChatIndex > -1 ? styles.open : null,
          ].join(' ')}
        >
          <div className={styles.conversasHeader} data-testid="container-chats-cabecalho">
            <TituloChat
              wsState={wsState}
              loading={chatState.loadingChats}
              onReloadChats={reloadChats}
              notificationStatus={notificationStatus}
              onAskPermission={askPermission}
            />
            {!userInteracted && (
              <AlertBox variant="warning">
                Clique aqui para ativar o som de novos atendimentos
              </AlertBox>
            )}
            <CampoTexto
              prepend={
                <Icon
                  path={mdiMagnify}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Pesquisar"
              value={query.search ?? ''}
              onChange={v => { setQuery({ ...query, search: v }) }}
            />
            <CampoSeletor
              disabled={chatState.loadingChats}
              placeholder="Todos os canais"
              options={canais}
              value={query.canalId?.toString() ?? ''}
              onChange={v => { setQuery({ ...query, canalId: +v! || null }) }}
            />
            {nomeCliente && (
              <HBox
                stretch
                style={{
                  border: '2px dashed var(--tc-color-gray-300)',
                  backgroundColor: 'var(--tc-color-gray-100)',
                  borderRadius: '12px',
                  padding: '4px 8px',
                }}
              >
                <span>{nomeCliente}</span>
                <Botao
                  variant="none-primary"
                  icon={mdiTrashCan}
                  onClick={() => {
                    setQuery({ ...query, userId: undefined });
                    navigate('/chat', { replace: true });
                  }}
                />
              </HBox>
            )}

            <Tabs
              className={styles.tabs}
              selectedTab={query.chatStatus}
              disabled={chatState.loadingChats}
              tabs={[
                { id: '', label: 'Todos', icon: mdiMessageProcessing },
                { id: 'chatbot', label: 'Chatbots', icon: mdiRobot },
                { id: 'atendimento', label: 'Atendimento', icon: mdiHeadset },
                {
                  id: 'finalizado',
                  label: 'Finalizados',
                  icon: mdiCheckCircle,
                },
              ]}
              onTabChange={tab => { setQuery({ ...query, chatStatus: tab }) }}
            />
          </div>
          <Conversas
            value={chatState.chats}
            selectedIndex={selectedChatIndex}
            setSelectedIndex={handleSelectedChatIndexChange}
            loadingChats={chatState.loadingChats}
            hasMoreChats={chatState.hasMoreChats}
            onLoadMoreChats={loadMoreChats}
            alertasAtendimento={chatState.fila}
            onAtender={chatId => atenderFila(chatId)}
            userInteracted={userInteracted}
          />
        </div>
        <div
          className={[
            styles.mensagensContainer,
            selectedChatIndex > -1 ? styles.open : null,
          ].join(' ')}
          data-testid="container-mensagens"
        >
          {selectedChatIndex > -1 && chatState.chats[selectedChatIndex] && chatState.selectedChatMessages && (
            <Mensagens
              chat={chatState.chats[selectedChatIndex]}
              value={chatState.selectedChatMessages}
              onChatEstadoChange={onChatEstadoChange}
              onSend={handleSend}
              onReloadChats={reloadChats}
              onChatAddTag={chatAddTag}
              isSending={chatState.sendingMessage}
              onSendAudio={handleSendAudio}
              isLoading={chatState.loadingMessages}
              hasMorePages={chatState.hasMorePages}
              onLoadMore={loadMoreMessages}
              onSelectedKbSection={setSelectedKbSection}
              onGoBack={() => {
                console.log('GOING BACK');
                handleSelectedChatIndexChange(-1);
              }}
              params={chatState.params}
            />
          )}
          {selectedChatIndex === -1 && (
            <div className={styles.mensagensPlaceholder} data-testid="container-mensagens-chat-nao-selecionado">
              <VBox>
                <div style={{ textAlign: 'center' }}>
                  <Icon
                    path={mdiMessageProcessing}
                    size="96px"
                    color="var(--tc-color-gray-300)"
                  />
                </div>
                <span>Selecione uma conversa para visualizar as mensagens</span>

                <Tutorial ytId="hUS2Jz3Vqa0" ytSi="yWs7_W975vO_XPYB" />
              </VBox>
            </div>
          )}
        </div>
      </div>
    </LayoutUsuario>
  );
};

export default Page;
