import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback, useMemo } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiMagnify, mdiPlus, mdiStar } from '@mdi/js';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import Grid, { GridItem } from '../../components/layout/Grid';
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import Modal from '@/components/layout/Modal';
import { FormVenda } from './FormVenda';
import { VendaDto } from '@tera/shared/src/types/VendaDto';
import { Raia } from './Raia';
import { CampoSeletorUsuarios } from './CampoSeletorUsuarios';
import { ModalImportarArquivo } from './ModalImportarArquivo';
import { useAuth } from '@/lib/useAuth';
import { FormMetadados } from '@/components/modelos/FormMetadados';
import { useDebounce } from '../chat/useDebounce';
import { Link } from 'react-router-dom';

const reorder = (
  value: Record<string, VendaDto[]>,
  startDid: string,
  endDid: string,
  startIndex: number,
  endIndex: number,
): Record<string, VendaDto[]> => {
  // TODO enviar as atualizações ao backend
  const listA = value[startDid];
  const listB = value[endDid];

  if (startDid === endDid) {
    const result = Array.from(listA);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return {
      ...value,
      [startDid]: result,
    };
  } else {
    const result = Array.from(listA);
    const [removed] = result.splice(startIndex, 1);

    const resultB = Array.from(listB);
    resultB.splice(endIndex, 0, removed);

    return {
      ...value,
      [startDid]: result,
      [endDid]: resultB,
    };
  }
};

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type Props = {};

const steps = [
  {
    id: 1,
    title: 'Prospect',
    name: 'prospect',
    color: 'var(--tc-color-gray-400)',
  },
  {
    id: 2,
    title: 'Qualificado',
    name: 'qualificado',
    color: 'var(--tc-color-gray-500)',
  },
  {
    id: 3,
    title: 'Proposta',
    name: 'proposta',
    color: 'var(--tc-color-gray-600)',
  },
  {
    id: 4,
    title: 'Negociação',
    name: 'negociacao',
    color: 'var(--tc-color-info)',
  },
  {
    id: 5,
    title: 'Ganhos',
    name: 'fechada',
    color: 'var(--tc-color-success)',
  },
  {
    id: 6,
    title: 'Perdidos',
    name: 'perdida',
    color: 'var(--tc-color-danger)',
  },
];

const Page = ({ }: Props) => {
  const { user } = useAuth();

  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const qResumo = useQuery({
    queryKey: ['atividade/resumo'],
    queryFn: async () => {
      const res = await axios.get('/api/atividade/resumo', {
        withCredentials: true,
      });

      return res.data.result;
    },
  })

  const [search, setSearch] = React.useState<string>('');
  const [filter, setFilter] = React.useState<any>({});
  const debouncedSearch = useDebounce(search, 500);

  const queryList = useQuery({
    queryKey: ['venda'],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
        fi: JSON.stringify(filter),
      });

      const result = await axios.get<ResponseList>(
        `/api/venda?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
      return result.data;
    },
  });

  const vendasByStatus = useMemo(() => {
    const v = steps.reduce(
      (acc, cur) => {
        const status = cur.name;
        if (!acc[status]) {
          acc[status] = [];
        }

        acc[status].push();

        return acc;
      },
      {} as Record<string, VendaDto[]>,
    );

    for (const venda of queryList.data?.result ?? []) {
      if (!v[venda.status]) {
        v[venda.status] = [];
      }
      v[venda.status].push(venda);
    }

    return v;
  }, [steps, queryList.data?.result]);

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(`/api/venda/${editObject.id}`, editObject, {
          withCredentials: true,
        });
      } else {
        return await axios.post(`/api/venda`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/venda/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Venda apagada com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar a venda.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({
      quantidade: 1,
      valor_total: 0,
    });
  };

  const [showImportModal, setShowImportModal] = React.useState(false);

  const handleImportModal = () => {
    setShowImportModal(true);
  };

  const reorderMutator = useMutation(
    async (data: any) => {
      const result = await axios.post('/api/venda/reorder', data, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar reordenar a venda.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const [isDragging, setIsDragging] = React.useState(false);
  const onDragStart = (start: any) => {
    setIsDragging(true);
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    setIsDragging(false);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newValue = reorder(
      vendasByStatus,
      result.source.droppableId,
      result.destination.droppableId,
      result.source.index,
      result.destination.index,
    );

    reorderMutator.mutate({
      id: result.draggableId,
      indice: result.destination.index,
      status: result.destination.droppableId,
    });
  };

  const [editCustomizeObject, setEditCustomizeObject] =
    React.useState<any>(null);
  const [customizeFieldErrors, setCustomizeFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const customizeMutator = useMutation(
    async () => {
      if (!editCustomizeObject) return;

      setCustomizeFieldErrors(null);

      return await axios.post(`/api/venda/meta`, editCustomizeObject, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        setCustomizeFieldErrors(null);
        setEditCustomizeObject(null);
        queryList.refetch();
        qCustomize.refetch();
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setCustomizeFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const [showCustomizeModal, setShowCustomizeModal] = React.useState(false);
  const qCustomize = useQuery(
    'venda/meta',
    async () => {
      const res = await axios.get('/api/venda/meta', {
        withCredentials: true,
      });

      return res.data.result;
    },
    {
      onSuccess: data => {
        setEditCustomizeObject(data);
      },
      refetchOnWindowFocus: false,
    },
  );

  const handleCustomizeModal = () => {
    setShowCustomizeModal(true);
    customizeMutator.reset();
  };

  const handleCloseCustomizeModal = () => {
    setShowCustomizeModal(false);
    setEditCustomizeObject([]);
    setCustomizeFieldErrors(null);
  };

  const handleChangeCustomization = () => {
    customizeMutator.mutateAsync();
  };

  return (
    <LayoutUsuario>
      <Modal
        ignoreOverlayClick
        title={editObject?.id > 0 ? 'Editar venda' : 'Nova venda'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormVenda
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>

      <Modal
        size="lg"
        ignoreOverlayClick
        title="Personalizar venda"
        show={showCustomizeModal}
        onClose={handleCloseCustomizeModal}
        onCancel={handleCloseCustomizeModal}
        onConfirm={handleChangeCustomization}
      >
        <p>Todos os produtos receberão os campos abaixo.</p>

        <FormMetadados
          value={editCustomizeObject}
          onChange={setEditCustomizeObject}
          fieldErrors={customizeFieldErrors}
        />
      </Modal>

      <ModalImportarArquivo
        show={showImportModal}
        onConfirm={() => {
          queryList.refetch();
        }}
        onClose={() => {
          setShowImportModal(false);
        }}
      />

      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Vendas</Titulo>
            <Descricao>Gerencie aqui suas vendas e clientes</Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="outline-primary"
              icon={mdiStar}
              onClick={handleCustomizeModal}
            >
              Personalizar...
            </Botao>
            <Botao
              variant="outline-primary"
              icon={mdiPlus}
              onClick={handleImportModal}
            >
              Importar...
            </Botao>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Nova
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <Grid>
          <GridItem sm={12} lg={6}>
            <CampoTexto
              prepend={
                <Icon
                  path={mdiMagnify}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Pesquisar"
              value={search}
              onChange={setSearch}
            />
          </GridItem>
          {user?.papel !== 'USUARIO' && (
            <GridItem sm={12} lg={6}>
              <CampoSeletorUsuarios
                value={filter?.id_usuario_vendedor?.[1]}
                onChange={v =>
                  setFilter({ ...filter, id_usuario_vendedor: ['in', v] })
                }
                placeholder="Buscar por vendedores"
              />
            </GridItem>
          )}
        </Grid>

        <div>
          <Botao variant="primary" onClick={() => queryList.refetch()}>
            Aplicar
          </Botao>
        </div>

        {qResumo.data?.['today'] && (
          <Link to={`/atividade`} style={{ textDecoration: 'none' }}>
            <div style={{ textAlign: 'center', fontSize: '1.25rem', color: 'var(--tc-color-primary)' }}>
              Você tem {qResumo.data['today'].length == 1 ? `1 atividade` : `${qResumo.data['today'].length} atividades`} para hoje: {qResumo.data['today'].join(', ')}.
            </div>
          </Link>
        )}

        {qResumo.data?.['tomorrow'] && (
          <Link to={`/atividade`} style={{ textDecoration: 'none' }}>
            <div style={{ textAlign: 'center', color: 'var(--tc-color-primary)' }}>
              Amanhã você tem {qResumo.data['tomorrow'].length == 1 ? `1 atividade` : `${qResumo.data['tomorrow'].length} atividades`}: {qResumo.data['tomorrow'].join(', ')}.
            </div>
          </Link>
        )}
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <HBox style={{ overflowX: 'auto', alignItems: 'flex-start' }}>
            {steps.map(step => (
              <Raia
                isDragging={isDragging}
                canDrop={!reorderMutator.isLoading}
                key={step.id}
                nome={step.name}
                titulo={step.title}
                corTitulo={step.color}
                value={vendasByStatus[step.name]}
                onEdit={setEditObject}
                onDelete={venda => handleDelete(venda.id)}
              />
            ))}
          </HBox>
        </DragDropContext>
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
