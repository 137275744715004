import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback, useMemo } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiLoading, mdiMagnify, mdiPen, mdiPlus, mdiTrashCan } from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import { FormClienteGrupo } from './FormClienteGrupo';
import Grid, { GridItem } from '../../components/layout/Grid';
import { parseISO, format } from 'date-fns';
import { title } from 'process';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type Props = {};

const TextoPersonalizacao = ({ type, value }: any) => {
  switch (type) {
    case 'data':
      return format(parseISO(value), 'dd/MM/yyyy');
    case 'booleano':
      return value ? 'Sim' : 'Não';
    case 'lista':
      return value && Array.isArray(value) ? value.join(', ') : value ?? '-';
    default:
      return value;
  }
};

const Page = ({ }: Props) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const qTags = useQuery('tags', async () => {
    const result = await axios.get('/api/marcador/box');
    return result.data?.result;
  });

  const headers: DataTableHeader[] = useMemo(
    () => [
      { label: 'Nome', width: '50%', column: 'nome' },
      { label: 'Qtd. clientes', width: '25%', column: 'total_clientes' },
      {
        label: 'Criado em',
        width: '25%',
        render: (row: any) =>
          format(parseISO(row.data_criacao), 'dd/MM/yyyy HH:mm'),
      },
      {
        label: 'Ações',
        width: '100px',
        stickRight: true,
        alignRight: true,
        render: (row: any) => {
          return (
            <HBox gap="8px">
              <Botao
                title="Editar"
                variant="none-primary"
                icon={mdiPen}
                onClick={() => setEditObject(row)}
              />
              <BotaoPerigoso
                buttonProps={{ title: 'Apagar' }}
                variant="none-danger"
                icon={mdiTrashCan}
                onClick={() => handleDelete(row.id)}
              />
            </HBox>
          );
        },
      },
    ],
    [qTags.data],
  );

  const queryList = useInfiniteQuery({
    queryKey: ['cliente_grupo'],
    queryFn: async ({ pageParam }) => {
      const filter: Record<string, any> = {};

      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
        fi: JSON.stringify(filter),
      });

      return await axios.get<ResponseList>(
        `/api/cliente_grupo?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(
          `/api/cliente_grupo/${editObject.id}`,
          editObject,
          {
            withCredentials: true,
          },
        );
      } else {
        return await axios.post(`/api/cliente_grupo`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
        if (!editObject.id) {
          toast('Grupo adicionado com sucesso', {
            toastId: 'add-success',
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast('Grupo alterado com sucesso', {
            toastId: 'edit-success',
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        }
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/cliente_grupo/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Grupo apagado com sucesso', {
          toastId: 'del-success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar o grupo.', {
          toastId: 'del-error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({});
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;
  const editMode = !!editObject?.id && editObject.id > 0;

  return (
    <LayoutUsuario>
      <Modal
        instanceId='modal-cliente-grupo'
        ignoreOverlayClick
        title={editMode ? 'Editar grupo' : 'Novo grupo'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormClienteGrupo
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>

      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Grupos de contatos</Titulo>
            <Descricao>
              Gerencie aqui os grupos de contatos de seus clientes.
            </Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Novo
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <Grid>
          <GridItem sm={12} lg={6}>
            <CampoTexto
              prepend={
                <Icon
                  path={mdiMagnify}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Pesquisar"
              value={search}
              onChange={setSearch}
            />
          </GridItem>
        </Grid>

        <div>
          <Botao variant="primary" onClick={() => queryList.refetch()}>
            Aplicar
          </Botao>
        </div>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
