import React, { ReactEventHandler, useEffect, useRef } from 'react';
import stylesTexto from './CampoTexto.module.css';

type CampoDinheiroProps = {
  label: string;
  value: number;
  placeholder?: string;
  onChange?: (value: number) => void;
  props?: unknown;
  mostrarSeparadorMilhar?: boolean;
  decimais?: number;
  somentePositivos?: boolean;
  disabled?: boolean;
  error?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  noBorder?: boolean;
  instanceId?: string;
};

type CursorPositionDir = 'forward' | 'backward' | 'none' | null;
type CursorPosition = {
  start: number;
  end: number | null;
  dir: CursorPositionDir;
};

const CampoDinheiro = ({
  label,
  value,
  placeholder,
  onChange,
  mostrarSeparadorMilhar = true,
  decimais = 2,
  somentePositivos,
  disabled,
  error,
  prepend,
  append,
  noBorder,
  instanceId,
  ...props
}: CampoDinheiroProps) => {
  const [cursorPosition, setCursorPosition] = React.useState<CursorPosition>({
    start: 0,
    end: 0,
    dir: 'forward',
  });

  const moneyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: decimais,
  });

  const moneyParser = (v: string | undefined): number => {
    if (!v) return 0;

    let val = String(v).replace(/\D/g, '');

    return Number(val) / Math.pow(10, decimais);
  };

  const [maskedValue, setMaskedValue] = React.useState<string>(() =>
    moneyFormatter.format(value),
  );

  const inputRef = React.useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current!.setSelectionRange(
      cursorPosition.start,
      cursorPosition.end,
      cursorPosition.dir as CursorPositionDir & undefined,
    );
  }, [cursorPosition]);

  useEffect(() => {
    const str = moneyFormatter.format(value);
    setMaskedValue(str);
    setCursorPosition({
      start: str.length,
      end: str.length,
      dir: 'forward',
    });
  }, [value]);

  return (
    <div>
      <label>{label}</label>
      <div
        className={[
          stylesTexto.container,
          error ? stylesTexto.error : null,
        ].join(' ')}
        style={{ border: noBorder ? 'none' : undefined }}
      >
        {prepend && <div className={stylesTexto.prepend}>{prepend}</div>}

        <input
          {...props}
          id={instanceId}
          ref={inputRef}
          type="tel"
          value={maskedValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const v = e.target.value;
            const i = moneyParser(v);

            const f = moneyFormatter.format(i);

            const diff = f.length - v.length;
            setCursorPosition({
              start: e.target.selectionStart! + diff,
              end: e.target.selectionEnd! + diff,
              dir: e.target.selectionDirection,
            });
            setMaskedValue(f);
            if (onChange) onChange(i);
          }}
          className={stylesTexto.input}
          style={{ border: noBorder ? 'none' : undefined }}
          disabled={disabled}
          onSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
            const firstDigit = e.target.value.match(/\d/);
            if (firstDigit == null) return;

            const pos9 = e.target.value.indexOf(firstDigit[0]);
            if (
              pos9 !== -1 &&
              e.target.selectionStart! < pos9 &&
              e.target.selectionStart === e.target.selectionEnd
            ) {
              setCursorPosition({
                start: pos9,
                end: pos9,
                dir: e.target.selectionDirection,
              });
            }
          }}
        />

        {append && <div className={stylesTexto.append}>{append}</div>}
      </div>
      {error && <div className={stylesTexto.error}>{error}</div>}
    </div>
  );
};

export default CampoDinheiro;
