import { CSSProperties } from 'react';
import styles from './Badge.module.css';

export type BadgeVariants =
  | 'primary'
  | 'secondary'
  | 'light'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info';

type BadgeProps = {
  variant?: BadgeVariants;
  style?: CSSProperties;
  children: React.ReactNode;
  [key: string]: any;
};

const Badge = ({ variant, children, style, ...props }: BadgeProps) => {
  return (
    <div
      {...props}
      style={style}
      className={[styles.badge, variant ? styles[variant] : null].join(' ')}
    >
      {children}
    </div>
  );
};

export default Badge;
